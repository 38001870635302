import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from 'store/auth-context'

// Material Kit 2 PRO React components
import MKBox from 'components/MKBox'

// Material Kit 2 PRO React examples
import SMUNavbar from 'commoncomponets/Navbars/SMUNavbar_main'
// import LandingNavbar from 'commoncomponets/Navbars/LandingNavbar'
import LandingFooter from 'commoncomponets/Footer/LandingFooter'

// Coworking page sections

import Iscriviti from 'pagesitemeup/Landing/sections/Iscriviti'
import Lead from 'pagesitemeup/Landing/sections/Lead'
import Collabs from 'pagesitemeup/Landing/sections/Collabs'
import Strenghts from 'pagesitemeup/Landing/sections/Strenghts'

// Routes
// import routes from 'routes'
import footerRoutes from 'footer.routes'

import { SMULoading } from '../../components/SMU/index'

export default function WebDesigner() {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const authContext = useContext(AuthContext)
  const [isLoggedIn, setIsLoggedIn] = useState(authContext.isLoggedIn)
  useEffect(() => setIsLoggedIn(authContext.isLoggedIn), [authContext])

  return (
    <>
      <SMULoading show={loading} />

      <MKBox variant="contained" bgColor="secondary" shadow="sm">
        <SMUNavbar />
      </MKBox>
      <MKBox component="header" position="relative">
        <Lead isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      </MKBox>
      <Iscriviti isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <Collabs isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      {/* <Register /> */}
      {/* <FormRegistrazione /> */}
      <Strenghts />

      <section className="p-0 m-0 bg-tertiary">
        <LandingFooter content={footerRoutes} />
      </section>
    </>
  )
}
