import { useEffect, useState } from 'react'
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { Dialog } from '@mui/material';
import { SMULoading } from 'components/SMU';
import useHttp from 'hooks/use-http'
import Countdown from 'react-countdown';
import { addDays, format } from 'date-fns'
import MKAvatar from 'components/MKAvatar'
import SMUModal from 'commoncomponets/Modal/SMUModal'
import ProgettoStandBy from 'assets/images/clessidra-in-corso.png'
import TemplateStandBy from 'assets/images/template-preview-no-image.png'
import I1 from 'assets/images/team-2.jpg'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
// import { Document, Page } from 'react-pdf'
import OffertaHeader from './sections/OffertaHeader'
import OffertaBody from './sections/OffertaBody'
import StatoLavoro from './sections/StatoLavoro'
import './DettagliOfferta.css'

import '../DettagliProgetto/DettagliProgetto.css'


export default function DettagliOffertaFreelance({offerta, profileData}) {

  const [confirm, setConfirm] = useState(0)
  const { id, titolo, localita, offers, profiles, budget, skill, descrizione, templates, templatesceltodafreelance, prodotti, durata, transactionid } = offerta

  const [offerDetails, setOfferDetails] = useState({})
  const [requestHelp, setRequestHelp] = useState(false)
  const [freelances, setFreelances] = useState([])
  const [selectedFreelanceType, setSelectedFreelanceType] = useState(0)
  const [allFreelances, setAllFreelances] = useState([])
  const [fullOffers, setFullOffers] = useState(offers)
  const [bestTeamPrice, setBestTeamPrice] = useState(0)
  const [bestTeamAccepted, setBestTeamAccepted] = useState(false)
  const [bestTeamAllSigned, setBestTeamAllSigned] = useState(false)
  const [receivingRequestsModal, setReceivingRequestsModal] = useState(true)

  const [contractSignatureExplained, setContractSignatureExplained] = useState(false)
  const [signContractURL, setSignContractURL] = useState(null)

  const [editTeam, setEditTeam] = useState(false)

  const {
    sendRequest: sendOfferRequest,
    isLoading: isOfferLoading,
    error: offerError
  } = useHttp()

  const {
    sendRequest: sendUserRequest,
    isLoading: isUserRequestLoading,
    error: userRequestError
  } = useHttp()

  const {
    sendRequest: sendFreelanceTypesRequest,
    isLoading: isFreelanceTypesLoading
  } = useHttp()

  const {
    sendRequest: sendAllFreelancesRequest,
    isLoading: isAllFreelancesLoading
  } = useHttp()

  const manageFreelanceTypes = data => {
    setFreelances(data?.data || [])
  }

  const loadFreelanceTypes = () => {
    sendFreelanceTypesRequest({
      url: '/sitemeup/tipologiefreelance',
      manageData: manageFreelanceTypes
    })
  }


  const manageAllFreelances = data => {
    setAllFreelances(data?.data || [])
    loadFreelanceTypes()
  }

  const loadAllFreelances = () => {
    sendAllFreelancesRequest({
      url: '/sitemeup/freelances',
      manageData: manageAllFreelances
    })
  }

  const manageOrderDetails = (dt) => {
    setOfferDetails(dt.data)
  }

  const refreshDetails = () => {
    sendOfferRequest({
      url: `/sitemeup/order?id=${id}`,
      manageData: manageOrderDetails
    })
  }

  useEffect(()=>{
    refreshDetails()
    loadAllFreelances()
  }, [id])

  const manageUserRequest = (dt) => {
    if (dt.status === 'OK')
      refreshDetails()
  }

  const managePaidRequest = (dd, dt) => {
    window.open(dt.strype_session.url, '_blank', 'noreferrer')
    refreshDetails()
    
  }
  const managePaymentRequest = (dt) => {
    window.open(dt.strype_session.url, '_blank', 'noreferrer')
    sendUserRequest({
      url: '/sitemeup/orderpayed',
      methodH:'POST',
      bodyH: {
        "id":id,
        "transactionid":"testok"
      },
      manageData: (dd)=>managePaidRequest(dd, dt)
    })
  }

  const startPayment = () => {
    console.log('a');
    sendUserRequest({
      url: '/sitemeup/paymentwithurl',
      methodH:'POST',
      bodyH: {
        "payment_type":"card",
        "order_id":id,
        "description":titolo
      },
      manageData: managePaymentRequest
    })

  }

  const scrollToOfferte = () => {
    document.getElementById('offerte').scrollIntoView({
      behavior: 'smooth'
    });
  }

  const verifyOffer = (idx, dp) => {
    sendUserRequest({
      url: '/sitemeup/orderoffercompleted',
      methodH:'POST',
      bodyH: {
        id:idx
      },
      manageData: manageUserRequest
    })

    
  }

  const completeOffer = (idx) => {
    sendUserRequest({
      url: '/sitemeup/orderoffercompleted',
      methodH:'POST',
      bodyH: {
        id:idx
      },
      manageData: manageUserRequest
    })
    
  }

  const revokeApproval = (idx) => {
    sendUserRequest({
      url: '/sitemeup/orderofferremovefromteam',
      methodH:'POST',
      bodyH: {
        id:idx
      },
      manageData: manageUserRequest
    })
  }


  const addToTeam = (idx) => {
    sendUserRequest({
      url: '/sitemeup/orderofferaddtoteam',
      methodH:'POST',
      bodyH: {
        id:idx
      },
      manageData: manageUserRequest
    })
  }


  const acceptBestTeam = () => {
    sendUserRequest({
      url: '/sitemeup/acceptbestteam',
      methodH:'POST',
      bodyH: {
        id
      },
      manageData: manageUserRequest
    })
  }

  const manageContractResponse = (dt) => {
    setSignContractURL(dt.offerta.chiavefirmafreelance)
  }

  const signContract = (offer) => {
    setContractSignatureExplained(true)
    if (!offer.chiavefirmafreelance) {
      sendUserRequest({
        url: '/sitemeup/startcontractsignature',
        methodH:'POST',
        bodyH: {
          id:offer.id
        },
        manageData: manageContractResponse
      })
    } else {
      setSignContractURL(offer.chiavefirmafreelance)
    }
  }
  

  useEffect(()=>{

    if (offerDetails?.offers) {
      setFullOffers(
        offerDetails?.offers.map((el)=>({...el, id_tipo_freelance:allFreelances.find((ej)=>ej.id === el.id_freelance)?.id_tipologia}))
      )
    } else {
      setFullOffers(
        offers.map((el)=>({...el, id_tipo_freelance:allFreelances.find((ej)=>ej.id === el.id_freelance)?.id_tipologia}))
      )
    }

  }, [allFreelances, offers, offerDetails])

  useEffect(()=>{ 
    if (offerDetails.bestteam) {
      let priceTotal = 0;
      let allAccepted = true;
      let allSigned = true;
      Object.keys(offerDetails.bestteam).map( (btf)=> { 
        priceTotal += offerDetails.bestteam[btf].offer.offerta;
        if (offerDetails.bestteam[btf].offer.stato === 0) {
          allAccepted = false;
        }
        if (!offerDetails.bestteam[btf].offer.flag_signed_cliente || !offerDetails.bestteam[btf].offer.flag_signed_freelance) {
          allSigned = false;
        }
        return btf 
      } )
      
      setBestTeamAccepted(allAccepted)
      setBestTeamPrice(priceTotal)
      setBestTeamAllSigned(allSigned)
    }

  },[offerDetails])

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setReceivingRequestsModal(false)
      return '';
    } 

    return (<>
      <div className="d-flex justify-content-center align-items-center">
          <div className="pe-3 me-3 countdownValue">
            <div style={{fontSize:'80px', fontWeight:'900', color:'#FFB703', lineHeight:'80px'}}>{String(days).padStart(2, '0')}</div>
            <div>GIORNI</div>
          </div>
          <div className="pe-3 me-3 countdownValue">
            <div style={{fontSize:'80px', fontWeight:'900', color:'#FFB703', lineHeight:'80px'}}>{String(hours).padStart(2, '0')}</div>
            <div>ORE</div>
          </div>
          <div className="pe-3 me-3 countdownValue">
            <div style={{fontSize:'80px', fontWeight:'900', color:'#FFB703', lineHeight:'80px'}}>{String(minutes).padStart(2, '0')}</div>
            <div>MINUTI</div>
          </div>
          <div>
            <div style={{fontSize:'80px', fontWeight:'900', color:'#FFB703', lineHeight:'80px'}}>{String(seconds).padStart(2, '0')}</div>
            <div>SECONDI</div>
          </div>
      </div></>);
  }


  return (<>

    <SMUModal 
      show={requestHelp}
      message={(<><h3>Compila il seguente form per richiedere aiuto al Project Manager.</h3></>)}
      clear={()=>setRequestHelp(false)}
    />
    
    <SMULoading show={isUserRequestLoading || isOfferLoading} />

    <Dialog fullWidth maxWidth="xl" open={signContractURL} onClose={()=>{setSignContractURL(null); refreshDetails()}}>
      <iframe title="YouSign - Firma contratto" width="100%" height="600" border="0" src={`https://staging-app.yousign.com/procedure/sign?members=${signContractURL}`} />
    </Dialog>

    <div className=" makeOfferProject">
      <div className="project-name">
        <div className="container">
          <div className="row align-items-center px-3">
            <div className="col-md-9">
              <span style={{fontSize:'0.75em', color:'#CEE0ED'}}><LocationOnIcon fontSize="small" color="#CEE0ED" /> {localita}</span><br />
              <h1>{titolo}</h1>
            </div>
            <div className="col-12 col-md-3">
              <section className="budget text-center">
                STIMA COSTO
                <span>€ {budget}</span>
                <span className="gold-background">{offerDetails?.offers?.length || offers.length} Offerte</span>
              </section>
            </div>

          </div>
        </div>
      </div>
  </div>
  <div className="dettagliOfferta">
    <div className="container pt-3">
      <div className="row px-3">

        <div className="col-md-4">
          {!templatesceltodafreelance ? (<>
            {templates.length > 0 ? (<>
              <img src={TemplateStandBy} alt="Template scelto" className="img-fluid" />
            </>) : (<>
              <button
                type="button"
                className="btn btn-primary"
              >
                Scegli template
              </button>
            </>)}
          </>) : (<>
          
            {offerDetails?.offers?.find((el)=>el.stato > 0 && el.stato < 7 && el?.template?.id) ? (<>
            <div className="template-scelto pb-4">
              <p>{offerDetails?.offers?.find((el)=>el.stato > 0 && el.stato < 7 && el?.template?.id)?.template?.nome}</p>
              <img src={offerDetails?.offers?.find((el)=>el.stato > 0 && el.stato < 7 && el?.template?.id)?.template?.immagine} alt="Template scelto" className="img-fluid" />
            </div>

          </>) : (<>
            Template scelto da freelance
            </>)}
          </>)}
          
        </div>
        <div className="col-md-8">
          <div className="bl-b-1 ps-4">
            <p><b className="fs-6 w-100 text-uppercase">DESCRIZIONE DEL PROGETTO</b></p>
            <p>{descrizione}</p>

            <p>
              <b className="fs-6 w-100 text-uppercase">Skills richieste:</b></p>
              <div className="list-skills row">
                <div className="col-lg-3 col-md-4 col-6 p-1">
                  <div className="skill text-truncate">BASE</div>
                </div>
              {skill.map(skil => (
                <div key={skil.id} className="col-lg-3 col-md-4 col-6 p-1">
                  <div className="skill text-truncate">{skil.name}</div>
                </div>
              ))}
              </div>
            </div>
        </div>


      </div>
    </div>


    { isOfferLoading ? (<>
    <p className="text-center d-none">Caricamento offerte in corso</p>
    </>) : (<>

  <div className="detailsContainer position-relative w-100 overflow-hidden" style={{minHeight:'800px'}}>

    {receivingRequestsModal ? (<>
    <div style={{ width:'100%', height:'100%', position:'absolute', top:'0', left:'0', background:'rgba(255,255,255,.5)', zIndex:2}} />
    <div className="d-flex justify-content-center" style={{ width:'100%', position:'absolute', top:'0', left:'0', paddingTop:'60px', zIndex:4}}>
      <div style={{background:'#013A63', padding:'60px 40px', color:'#FFF', height:'auto',  width:'70%', borderRadius:20}}>

        <div className="text-center">
          <img src={ProgettoStandBy} alt="Requests" width="200" />
          <h4 className="text-white" style={{textTransform:'none', fontSize:'1.6em'}}>Stiamo ricevendo le tue offerte per preparare<br />il team migliore per le tue esigenze</h4>
          <Countdown renderer={renderer} date={addDays(new Date(offerta?.created_at), durata)} />

          <p><button type="button" className="btn" onClick={()=>setReceivingRequestsModal(false)}>Chiudi</button></p>
        </div>

      </div>
    </div>
    </>) : null}
    

    <div className={receivingRequestsModal ? 'blurred pb-5' : 'pb-5'}>


    {offerDetails.offers ? (<>
    <section className="bg-secondary p-3">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center">
                <span className="flex-auto text-nowrap" style={{fontSize:'1.5em', color:'#FFB703', fontWeight:900}}>
                  Il progetto /
                </span>
                <span className="text-white ms-2" style={{fontSize:'0.8em'}}>
                  Gestisci il progetto comunicando con il Project Manager e segnalando il completamento del lavoro
                </span>
            </div>
                
          </div>
        </div>
        <div className="mt-3 row align-items-center">
          <div className="col-md-7">
            <div className="d-flex align-items-center">
              <MKAvatar
                src={I1}
                alt="Project Manager"
                shadow="md"
                variant="rounded"
              />
              <p className="ms-3 mb-0 text-white">
                PROJECT MANAGER (SITEMEUP)<br />
                <span className="text-primary">Alessandro Giansante - Roma</span>
              </p>
            </div>
          </div>
          <div className="col-md-5 text-md-end">
            <div className="d-flex align-items-center justify-content-end">
              <button type="button" className="btn btn-yellow text-uppercase mb-0" onClick={()=>setRequestHelp(true)}>
                Contatta
              </button>
            </div>
          </div>
          <div className="col-12 mb-3">
            <hr style={{borderColor:'#fff', color:'#fff', backgroundColor:'#fff'}} />
          </div>
        </div>


        
        
        { offerDetails.offers.filter((el)=>el.id_freelance === profileData.id).map((of)=> {

          const freelance = allFreelances.find((el)=>el.id === of.id_freelance)
          const freetype = freelances.find((el)=>el.id === freelance?.id_tipologia)
            console.log(of, 'OFFERTA?')
          return (<>
            <div className="row mb-2">
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <MKAvatar
                    src={freelance?.pathfoto || ''}
                    alt={`${freelance?.nome} ${freelance?.cognome}`}
                    shadow="md"
                    variant="rounded"
                  />
                  <p className="ms-3 mb-0 text-white">
                    {`${freetype?.name?.toUpperCase()}`}<br />
                    <span className="text-primary">{freelance?.nome} {freelance?.cognome}</span>
                  </p>
                </div>
              </div>
             
                <div className="col-md-6 text-md-end">
                  <div className="d-flex align-items-center justify-content-end">
                   
                    {of.flag_signed_freelance ? (<>

                        {of.flag_signed_cliente ? (<>
                          <a href={of?.contrattopath} rel="noreferrer" target="_blank" className="d-inline-block text-white small text-center p-2 me-4 btn "  style={{fontSize:'0.7em', color:'#fff !important'}}>
                            Scarica contratto
                          </a>
                        </>) : (<>
                            <button type="button" disabled className="d-inline-block btn-white text-white small text-center p-2 me-4 btn"  style={{fontSize:'0.7em'}}>
                                In firma dal cliente
                            </button>

                        </>)}

                    </>) : (<>
                        <button type="button" className="d-inline-block btn-white text-white small text-center p-2 me-4 btn"  style={{fontSize:'0.7em'}} onClick={()=>signContract(of)}>
                            Firma contratto
                        </button>
                    </>)}
                    

                    <button type="button" disabled={of?.stato !== 4} className="d-inline-block text-white small text-center p-2 me-4 btn btn-primary"  style={{fontSize:'0.7em'}} onClick={()=>verifyOffer(of?.id, 1)}>
                     {of?.stato === 6 ? (<>Incarico completato!</>) : (<>
                        {of?.stato === 5 ? (<>In attesa di verifica</>) : (<>
                            Completa l&lsquo;incarico
                        </>)}
                     
                     </>)}
                    </button>
                    
                  </div>
                </div>
            </div>
          </>)

        }) }
        
      </div>
    </section>
    </>) : null}


    </div>

    </div> { /* dettagliOfferta */ }
    </>)}
  </div>
</>)
}
