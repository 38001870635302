import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import MKButton from 'components/MKButton'

import imgColl1 from 'assets/images/sitemeup/semplice.svg'
import imgColl2 from 'assets/images/sitemeup/budget.svg'
import imgColl3 from 'assets/images/sitemeup/professionalita.svg'
import geometricA from 'assets/images/sitemeup/geometricA.svg'
import geometricB from 'assets/images/sitemeup/geometricB.svg'

import genId from 'idGenerator'
import LoginForm from 'components/SMU/Login/LoginForm'
import { useDispatch } from 'react-redux'
import { clearProfile } from 'slices/profileSlice'

// TBD
// import { FormattedMessage } from 'react-intl'

export default function Collabs({ isLoggedIn, setIsLoggedIn }) {
  const [show, setShow] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const arrayInfo = [
    {
      img: imgColl1,
      title: 'site me up è semplice e sicuro',
      desc: 'Seleziona il progetto web che meglio si sposa alle tue capacità manda un preventivo e inizia a lavorare.'
    },
    {
      img: imgColl2,
      title: 'Su Site Me Up solo budget adeguati',
      desc: 'Site Me Up verifica il budget del cliente con il costo di mercato. Niente più lavori sottocosto!'
    },
    {
      img: imgColl3,
      title: 'Site Me Up premia la tua professionalità',
      desc: 'Ogni anno Site Me Up riconosce premi e contratti a tempo indeterminato ai migliori freelancer del portale.'
    }
  ]

  return (
    <>
      <section className="py-5 bg-white">
        <div className="container-fluid position-relative overflow-hidden">
          <img
            src={geometricA}
            className="geometricA_rlogo_landing z-index-0"
            alt="Site Me Up - theme"
          />
          <img
            src={geometricB}
            className="geometricB_llogo_landing z-index-0"
            alt="Site Me Up - theme"
          />
          <div className="row position-relative z-index-3">
            <div className="col-12 col-lg-10 m-auto">
              <div className="container px-0">
                <div className="row px-0">
                  <div className="col-12 text-center zilla px-0 px-md-1">
                    <h2>stiamo cercando nuovi collaboratori!</h2>
                    <p className="subtitle text-center">
                      Entra a far parte della nostra community
                    </p>
                  </div>

                  {arrayInfo.map(e => (
                    <div
                      key={genId(3)}
                      className="col-12 col-lg-4 zilla px-lg-5 my-lg-4"
                    >
                      <div className="pb-4 pb-lg-0 mb-3 mb-lg-2 position-relative text-center cover">
                        <img
                          src={e.img}
                          alt="Professionalità"
                          className="img-fluid"
                        />
                      </div>
                      <div className="text-center text-lg-start">
                        <h3 className="text-secondary">{e.title}</h3>
                        <p>{e.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-12 text-center">
                  <LoginForm
                    show={show}
                    sendLogin={() => {
                      setIsLoggedIn(true)
                      navigate('/profilo')
                    }}
                    sendLogout={() => {
                      dispatch(clearProfile)
                      setIsLoggedIn(false)
                    }}
                    close={() => setShow(false)}
                  />
                  <MKButton
                    onClick={() =>
                      !isLoggedIn ? setShow(true) : navigate('/profilo')
                    }
                    className="btn btn-lg btn-primary my-4"
                  >
                    SCOPRI DI PI&ugrave;
                  </MKButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
