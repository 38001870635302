/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AuthContext } from 'store/auth-context'
// import { useNavigate } from 'react-router-dom'
import { setLoading, removeLoading } from 'slices/loadingSlice'
import { dumpProfile, selectProfile } from 'slices/profileSlice'
import { dumpUser, selectIsEmailVerified } from 'slices/userSlice'
import useHttp from 'hooks/use-http'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import SMUNavbar from 'commoncomponets/Navbars/SMUNavbar_main'
import SMUFooter from 'commoncomponets/Footer/SMUFooter'
import DettagliOffertaFreelance from 'pagesitemeup/DettagliOfferta/DettagliOffertaFreelance'
import DettagliOfferta from 'pagesitemeup/DettagliOfferta'
import footerRoutes from 'footer.routes'
import SMUModal from 'commoncomponets/Modal/SMUModal'
import Notifiche from 'pagesitemeup/Notifiche'
import { SMULoading } from 'components/SMU'
import ClientPayments from './Client/components/ClientPayments'
import ProfileBody from './components/ProfileBody'
import Sidebar from './components/Sidebar'
import Drawer from './components/RightDrawer'
import Wallet from '../Wallet/Wallet'
import Portfolio from '../Portfolio/Portfolio'
import StoricoProgetti from './sections/Progetti'
import StoricoProgettiCliente from './Client/sections/Progetti'
import SMUDrawer from './Client/components/Drawer'
import ClientSidebar from './Client/components/Sidebar'


import 'pagesitemeup/Profilo/components/LeftProfileNavigation.css'
import 'pagesitemeup/Profilo/Profilo.css'



const NotificheUtente = () => {

    const authContext = useContext(AuthContext)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const profileData = useSelector(selectProfile)
    const [notifications, setNotifications] = useState([])
    const userdata = JSON.parse(localStorage.getItem('userdata'))

    // const dispatch = useDispatch()

    const {
        sendRequest: checkNotifications,
        isLoading: isNotificationsLoading,
        error: notificationsError
    } = useHttp()
    
    const manageNotificationResponse = (dt) => {
        setNotifications(dt.data)

        const idnot = userdata.tipo === 'freelance' ? userdata?.freelancedata?.id : userdata?.clientedata?.id;
        checkNotifications({
            url: '/sitemeup/notificaupdateforuser',
            methodH: 'post',
            bodyH: { stato:2, id_utente:idnot },
            manageData: ()=>null
          })
    }

    const [show, setShow] = useState(false)
    const [user, setUser] = useState({
        email: authContext.email,
        name: authContext.name,
        id: authContext.id,
        token: authContext.token,
        isLoggedIn: authContext.isLoggedIn
    })
    const [isLoggedIn, setIsLoggedIn] = useState(authContext.isLoggedIn)

    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        
        // console.log('userData from loginButton :', userdata)
        if (userdata) {
            if (userdata.isLoggedIn) {
                const idnot = userdata.tipo === 'freelance' ? userdata?.freelancedata?.id : userdata?.clientedata?.id;
                setIsLoggedIn(true)
                setUser(userdata)

                if (idnot) {
                    checkNotifications({
                    url: `/sitemeup/notifiche?id_utente=${idnot}`,
                    manageData: manageNotificationResponse
                    })
                    
                }

            }
        }
    }, [localStorage.getItem('userdata')])
    

  return (
    <>
          <SMUNavbar />
          <SMULoading show={isNotificationsLoading} />
          <div className="container">
            <div className="row">
                <div className="col-12">
                    <Notifiche notifications={notifications} />
                </div>
            </div>
          </div>
            
                 
    </>
  )
}

export default NotificheUtente
