/* eslint-disable no-restricted-syntax, guard-for-in */
import { useEffect, useState, useContext } from 'react'
import { AuthContext } from 'store/auth-context'

import TemplateBox from 'pagesitemeup/CreaProgetto/components/TemplateBox'
import ImgClientEsempio from 'assets/images/ivana-square.jpg'
import Programmatore from '../../CreaProgetto/sections/images/webdeveloper.png';

export default function DettagliBody({ project, freelances, client }) {
  const { desc, descrizione, skills, profiles, tipoprogetto, siti, images } = project

  const authContext = useContext(AuthContext)
  const [isLoggedIn, setIsLoggedIn] = useState(authContext.isLoggedIn)

  const tipoFreelance = authContext.authState?.freelancedata?.id_tipologia

  
  const freelance =
    profiles.find(el => el.id_tipofreelance === tipoFreelance) || {}

  const [selectedFreelanceDescription, setSelectedFreelanceDescription] = useState(tipoFreelance || profiles[0]?.id_tipofreelance);

  useEffect(() => {
    console.log(project)
    console.log(freelance)
  }, [])

  return (<>

      <div className="container p-0">
        <div className="row">
          <div className="col-md-4 background-grey">
            <div className="info-link mb-3 text-center text-md-start">
              <h5>Template</h5>
              <p>
                {project.templatesceltodafreelance === 1
                  ? 'Scelto dal freelance'
                  : 'Scelto dal cliente'}
              </p>
              {!project.templatesceltodafreelance ? (<>
              <p><strong>{project?.templates[0]?.nome || 'Non ancora scelto'}</strong></p>
                { project?.templates[0]?.immagine ? (<>
                  <p><img src={project?.templates[0]?.immagine} alt={`Template scelto: ${project?.templates[0]?.nome}`} className="img-fluid" /></p>
                </>) : null}
              </>) : null}

              {siti.length > 0 ? (<>
                <h5 className="mt-5">Link d&apos;ispirazione</h5>
                {siti.map((el)=>(<><p><a href={el.sito} rel="noreferrer" target="_blank"><span>{el.sito}</span></a></p></>))}
              </>) : null}

            {images.length > 0 ? (<>
              <h5 className="mt-5">
                Documenti d&apos;ispirazione
              </h5>
              {images.map((el)=>(<><p><a href={el.imagepath} rel="noreferrer" target="_blank"><span>{el.name}</span></a></p></>))}
              
              </>) : null}
            </div>

          </div>
          <div className="col-md-8 pt-3">
            <div className="row">
              <div className="col-6">
                <h5>Tipologia</h5>
                {tipoprogetto?.nome || "Tipologia non impostata"}
                
              </div>
              <div className="col-6 text-right">
                  <div className="d-inline-block text-start">
                  <h5>Cliente</h5>
              <span className="avatar-client mx-auto">
                <img
                  src={ImgClientEsempio}
                  alt=""
                  className="col-12 col-md-auto"
                />
                {isLoggedIn ? (<>@{client?.nome} {client?.cognome}</>) : (<>Effettua il login per vedere.</>)}
                
              </span>
                  </div>
              </div>
            </div>
            <hr className='mb-5' style={{color:'#0565a2', opacity:1}} />

           

                  
            <h5>Descrizione del progetto</h5>
            <p>{descrizione || 'Nessuna descrizione inserita'}</p>

            <div>
            <b className="fs-6 w-100 text-uppercase">Skills richieste:</b>
            <div className="list-skills row">
              <div className="col-lg-3 col-md-4 col-6 p-1">
                <div className="skill text-truncate">BASE</div>
              </div>
            {skills.map(skill => (
              <div key={skill.id} className="col-lg-3 col-md-4 col-6 p-1">
                <div className="skill text-truncate">{skill.name}</div>
              </div>
            ))}
            </div>
            </div>

          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12 pt-5">
            
            <div className="d-profiles text-center">
              <h5 className="mb-3">Professionisti necessari:</h5>
              {profiles.map(el => (<>
                <img src={freelances.find((fl)=>fl.id === el.id_tipofreelance)?.pathfoto || Programmatore} height="100" className="me-2" alt={freelances.find((fl)=>fl.id === el.id_tipofreelance)?.name} />
              </>)
              
            )}
            </div>

          </div>
        </div>
      </div>




    <div className="container p-0">
      <div className="row">
      <div className="col-12">
      <div className="row">
        <section className="col-12 col-md-9 list-skills mt-3 mt-md-0 text-center text-md-start">
          <div className='row'>
          <div className="col-12 d-flex">
            <button type="button" className="btn skill text-truncate me-2" disabled 
                    style={{ background: '#EDF1F4', color: '#CEE0ED', borderBottomLeftRadius:0, borderBottomRightRadius:0 }}
            >
              Project Manager
            </button>
          
          {profiles.map(el => (<>
              {el.id_tipofreelance === selectedFreelanceDescription ? (
                <>
                  <button type="button" className="btn me-2 skill text-truncate" disabled 
                    style={{ background: '#0565a2', color: '#FFF', opacity:1, borderBottomLeftRadius:0, borderBottomRightRadius:0 }}
                  >
                    {freelances.find(ej => ej.id === el.id_tipofreelance)?.name}
                  </button>
                </>
              ) : (
                <>
                  <button type="button" onClick={(e)=>setSelectedFreelanceDescription(el.id_tipofreelance)} className="btn me-2 skill text-truncate" style={{borderBottomLeftRadius:0, borderBottomRightRadius:0, cursor:"pointer"}}>
                    {freelances.find(ej => ej.id === el.id_tipofreelance)?.name}
                  </button>
                </>
              )}
          </>))}
          </div>
        </div>
        </section>
</div>
        {freelances.find(ej => ej.id === selectedFreelanceDescription) ? (<>

          <section id="recapFreelance">
            <div className="row">
              <div className="col-12">
                Budget stimato per il {freelances.find(ej => ej.id === selectedFreelanceDescription)?.name}<br />
                <span className="text-price text-primary">€ {profiles.find((el)=>el.id_tipofreelance === selectedFreelanceDescription)?.budget.toFixed(2) || 0}</span>
                <hr style={{color:'#0565a2', opacity:1}} />
              </div>
            </div>
            <div className="row">
              <div className="row">
                <h5>Specifiche per il progetto</h5>
                <p><strong>Descrizione attività:</strong><br />
                {profiles.find(ej => ej.id_tipofreelance === selectedFreelanceDescription)?.attivita}</p>

                {Object.keys(profiles.find(ej => ej.id_tipofreelance === selectedFreelanceDescription)?.answers).map((el)=>
                   (<>
                    <h6>{el}</h6>
                    {profiles.find(ej => ej.id_tipofreelance === selectedFreelanceDescription)?.answers[el].map((ej)=>(<>
                    <p>
                      <strong>{ej?.text}</strong><br />
                      {ej?.answer?.answer}
                    </p>
                    </>))}
                  </>)

                )}
              </div>
            </div>
          </section>

        </>) : null}

{ false ? (
        <div className="add-products lato mt-0 bg-white mb-3 mt-4">
          <h5 className="m-0 bg-white text-secondary text-center text-md-start p-0 ps-4">
            Prodotti aggiuntivi
          </h5>

          <div className="container mx-auto bg-white pt-3 list-skills">
            <div className="col-12 p-1">
              {project?.prodotti.length > 0 ? (
                <>
                  {project?.prodotti.map(pp => (
                    <div
                      key={`product_${pp.id}`}
                      className="skill text-truncate"
                    >
                      {pp.nome}
                    </div>
                  ))}
                </>
              ) : (
                <>Nessun prodotto aggiuntivo selezionato</>
              )}
            </div>
          </div>
        </div>) : null}
      </div>
      </div>
    </div>
    </>)
}
