import './ProjectPanel.css'
import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from 'store/auth-context'
import { useNavigate } from 'react-router-dom'
// import { useSelector, useDispatch } from 'react-redux'
import { dumpProfile, selectProfile, clearProfile } from 'slices/profileSlice'
import { Tooltip } from '@mui/material'
import LoginForm from 'components/SMU/Login/LoginForm'

import templateImg from 'assets/images/sitemeup/creaprogetto/template_view.png'
import P1 from 'assets/images/products/product-1-min.jpg'
import { useDispatch, useSelector } from 'react-redux'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Programmatore from '../../CreaProgetto/sections/images/webdeveloper.png';



export default function ProjectPanel({ item, freelances }) {
  const authContext = useContext(AuthContext)
  // console.log(authContext)
  const isApproved = authContext.authState?.freelancedata?.approved
  const tipoFreelance = authContext.authState?.freelancedata?.id_tipologia
  const freelanceID = authContext.authState?.freelancedata?.id
  const [show, setShow] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(authContext.isLoggedIn)
  const isClient = authContext.authState.tipo === 'cliente'

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    id,
    titolo,
    localita,
    budget,
    descrizione,
    data,
    templatesceltodafreelance,
    numeroofferte,
    profiles,
    canoffer,
    canoffermsg,
    skilltipoprogetto: skills
  } = item

  useEffect(() => {
    console.log(item, 'ITEM')
    console.log(authContext.authState)
    console.log(freelances)
  }, [])

  return (
    <div className="project-panel lato" key={id} id={`project-${id}`}>
      <section className="col-12 name">
        <div className="d-flex align-items-center">
            <div>
              <span style={{fontSize:'0.75em', color:'#CEE0ED'}}><LocationOnIcon fontSize="small" color="#CEE0ED" /> {localita}</span><br />
              {titolo}
              
            </div>
            <div className="d-profiles" style={{borderLeft:'1px solid #fff', marginLeft:10, paddingLeft:10}}>
              {profiles.map(el => (<>
                <Tooltip title={freelances.find((fl)=>fl.id === el.id_tipofreelance)?.name} ><img src={freelances.find((fl)=>fl.id === el.id_tipofreelance)?.pathfoto || Programmatore} className="me-3" height="55" alt={freelances.find((fl)=>fl.id === el.id_tipofreelance)?.name} /></Tooltip>
              </>))}
            </div>
        </div>
        
      </section>

      <section className="body">

      <section className="list-skills mt-4 mt-md-2 text-center text-md-start flex-grow-1">
        <div className="col-12 col-md-9">
          <b className="ps-2 fs-6 w-100">Skills richieste</b>
          <div className="row">
          {skills.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            ))
          ).map(skill => (
            <div key={skill.id} className="col-lg-3 col-md-4 col-6 p-1">
              <div className="skill text-truncate">{skill.name}</div>
            </div>
          ))}
          </div>
          </div>
          <div className="col-12 col-md-3">
            <section className="budget">
              STIMA COSTO
              <span>€ {budget}</span>
              <span className="gold-background">{numeroofferte} Offerte</span>
            </section>
          </div>
      </section>

        
          
        


      
        

        <section className="col-12 col-md-12 mt-3 mt-md-0 text-center text-md-start mb-3 p-1">
          {descrizione}
        </section>

        

        

        
      </section>
      <section className="p-2 mt-2 project-panel-footer px-4">
        <div className="row  align-items-center">
          <div className="col-md-8">
            {isApproved && canoffer && freelanceID && profiles.find((el)=>el.id_tipofreelance === tipoFreelance) ? (<>
              { canoffer ? (<>
                
                <strong className="text-primary">Il tuo profilo è corrispondente alla figura richiesta!</strong>
              </>) : null}
            </>) : (<>
              {isClient || !isLoggedIn ? null : (<>
               
                {!isApproved ? (<>
                  <strong className="text-primary">{canoffermsg || "Il tuo profilo non è ancora stato approvato."}</strong>
                </>) : (<>
                  <strong className="text-primary">{canoffermsg || "Competenze Mancanti!"}</strong>
                </>)}
                
              </>)}
            </>)}
          </div>
          <div className="col-md-4 text-end">
            <LoginForm
              show={show}
              origin={`/progetto/${id}`}
              sendLogin={target => {
                setIsLoggedIn(true)
                navigate(target)
              }}
              sendLogout={() => {
                dispatch(clearProfile)
                setIsLoggedIn(false)
              }}
              close={() => setShow(false)}
            />

            
              <button
                type="button"
                key='button_login'
                className="btn btn-primary fs-7 mb-0 fw-bold"
                /* eslint-disable */
                onClick={() => {
                  navigate(`/progetto/${id}`)
                }}
              >
                Guarda la scheda
              </button>
            </div>
          </div>
        </section>
    </div>
  )
}


