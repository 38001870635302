import React, {useState, useEffect } from 'react'
// import React, { useState, useCallback, useEffect, useContext } from 'react'
import Input from '@mui/material/Input'
import { Select, MenuItem, Dialog } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import TemplateBox from 'pagesitemeup/CreaProgetto/components/TemplateBox'
import TemplateView from 'pagesitemeup/CreaProgetto/components/TemplateView'
import useHttp from 'hooks/use-http'
import template1 from 'assets/images/sitemeup/creaprogetto/template_example1.png'
import PanelSelezione from 'pagesitemeup/CreaProgetto/components/PanelSelezione'
import PreviewTemplate from '../PreviewTemplate'

const ScegliTemplate = props => {
  const { project, projectTypes, handleNext, handleBack, templates, sendProjectTemplate } = props
  const { titolo, descrizione, tiposito, projectType, localita } = project

  const [selectedTemplate, setSelectedTemplate] = useState(0)
  const [templateObj, setTemplateObj] = useState(null)

  return (<>
    

    {  templateObj === null ? (<>
    <div className="col-11 mx-auto">
      <h2 className="text-center">Seleziona il template</h2>
      <div className="row mt-3">
        
        <div className="col-12 text-center">
            


               

                <div className="template-list">
                {templates.map((template)=>(<>
                <div
                    className='d-block col-6 col-lg-4 p-2 false'
                >
                    <button type='button' onClick={()=>setSelectedTemplate(template.id)} className={`template-box${selectedTemplate === template.id ? ' active': ''}`}>
                 
                            
                        <div className="d-block text-center">
                        <img
                          src={template.immaginepath || template1}
                          className="img-fluid mb-2"
                          alt="template"
                        />
                        
                        
                        </div>

                        <div className="d-flex col-12 z-index-2 px-2 px-md-3 pb-3 pt-2 ">
                        <div className="d-flex col-7 fs-small text-start">
                            <b>
                            {template.nome}
                            </b>
                        </div>
                        <div className="d-flex col-5 align-items-end justify-content-end">
                            <a
                            href="#checkTemplate"
                            type="button"
                            className="btn btn-sm btn-primary m-0 py-2 px-3 fs-small"
                            onClick={(e) => { e.preventDefault(); setTemplateObj(template); }}
                            >
                            Dettagli
                            </a>
                        </div>
                        </div>
                    </button>
                
                </div>
                </>))}
                </div>
        </div>
      </div>
    </div>
    <div className="row">
            <div className="col-12 mt-4">
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-lg btn-primary ms-0 me-auto letter-spacing-1"
                  alt=""
                  onClick={() => handleBack()}
                >
                  <small>Indietro</small>
                </button>
                
                  <button
                    type="button"
                    className="btn btn-lg btn-primary ms-auto me-0 letter-spacing-1"
                    alt=""
                    disabled={selectedTemplate === 0}
                    onClick={() => sendProjectTemplate(selectedTemplate)}
                  >
                    <small>Seleziona</small>
                  </button>
              </div>
            </div>
          </div>
    </>) : (<> 
        <PreviewTemplate template={templateObj} handleNext={() => handleNext()} />
        <div className="row">
            <div className="col-12 mt-4">
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-lg btn-primary ms-0 me-auto letter-spacing-1"
                  alt=""
                  onClick={() => setTemplateObj(null)}
                >
                  <small>Indietro</small>
                </button>
               
                  <button
                    type="button"
                    className="btn btn-lg btn-primary ms-auto me-0 letter-spacing-1"
                    alt=""
                    onClick={() => sendProjectTemplate(selectedTemplate)}
                    disabled={selectedTemplate === 0}
                  >
                    <small>Seleziona</small>
                  </button>
              </div>
            </div>
          </div>
    </>)}
      

    
    </>)
}

export default ScegliTemplate

