import React, { useState } from 'react'

const DesignerChoice = props => {
  const { project, sendUpdate, handleNext, handleBack, publishProject } = props
  const { templatesceltodafreelance } = project

  const updateChoice = c => {
    sendUpdate('templatesceltodafreelance', c)
  }

  return (
    <div className="container mx-auto" style={{ marginTop: '8%' }}>
      <div className="col-11 mx-auto">
        <button
          type="button"
          className={`opacity-5 p-0 mb-3 bg-white border-radius-lg ${
            templatesceltodafreelance && 'border-success border-3 opacity-10'
          }`}
          onClick={() => updateChoice(1)}
          style={{ borderStyle: 'solid' }}
        >
          <div className="col-12 mx-auto">
            <div className="card p-3 mt-3 p-lg-4 text-center text-lg-start">
              <div className="row d-flex justify-content-center zilla">
                <div className="col-12 mb-lg-0 mb-4">
                  <p className="text-lg text-secondary">
                    <b>Fai scegliere il template al freelancer</b>
                  </p>
                  <p className="text-secondary mb-0">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremquelaudantium, totam rem
                    aperiam eaque ipsa
                  </p>
                </div>
              </div>
            </div>
          </div>
        </button>

        <button
          type="button"
          className={`opacity-5 p-0 mb-3 bg-white border-radius-lg ${
            !templatesceltodafreelance && 'border-success border-3 opacity-10'
          }`}
          onClick={() => updateChoice(0)}
          style={{ borderStyle: 'solid' }}
        >
          <div className="col-12 mx-auto">
            <div className="card p-3 mt-3 p-lg-4 text-center text-lg-start">
              <div className="row d-flex justify-content-center zilla">
                <div className="col-12 mb-lg-0 mb-4">
                  <p className="text-lg text-secondary">
                    <b>Scelgo io il template</b>
                  </p>
                  <p className="text-secondary mb-0">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremquelaudantium, totam rem
                    aperiam eaque ipsa
                  </p>
                </div>
              </div>
            </div>
          </div>
        </button>

        <div className="container">
          <div className="row">
            <div className="col-12 mt-4">
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-lg btn-primary ms-0 me-auto letter-spacing-1"
                  alt=""
                  onClick={() => handleBack()}
                >
                  <small>Indietro</small>
                </button>
                <button
                  type="button"
                  className="btn btn-lg btn-primary ms-auto me-0 letter-spacing-1"
                  alt=""
                  onClick={() => publishProject()}
                >
                  <small>{`Pubblica${
                    !templatesceltodafreelance ? ' e scegli' : ''
                  }`}</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DesignerChoice
