import { TextField, FormControl, Input } from '@mui/material';
import { useEffect, useState } from "react";
import PlacesAutocomplete, {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
} from 'react-places-autocomplete';

import './PlacesAutocompleter.css';

const PlacesAutocompleter = (props) => {

    const [searchaddress, setSearchaddress] = useState('')
    const { placeholder, place, sendPlaceData, formStyle, vari, simpleForm } = props

    
    const handleChange = (address) => { 
        console.log(address)
        sendPlaceData(address)
    }
    const handleSelect = (address) => {
        let res = {}
        console.log(address, "handleSelect")
        geocodeByAddress(address)
        .then(results => { 
            res = {
                searchaddress:address,
                streetno:results[0].address_components.find((el)=>el.types.includes('street_number'))?.long_name || '',
                city:results[0].address_components.find((el)=>el.types.includes('administrative_area_level_3'))?.long_name || '',
                province:results[0].address_components.find((el)=>el.types.includes('administrative_area_level_2'))?.short_name || '',
                region:results[0].address_components.find((el)=>el.types.includes('administrative_area_level_1'))?.long_name || '',
                country:results[0].address_components.find((el)=>el.types.includes('country'))?.short_name || '',
                postalcode:results[0].address_components.find((el)=>el.types.includes('postal_code'))?.long_name || '',
                addr:results[0].address_components.find((el)=>el.types.includes('route'))?.long_name || '',
                "searched":true
            };
            
            setSearchaddress(address)
            return getLatLng(results[0])} )

        .then(latLng => { 
            console.log(res); 
            console.log('Success', latLng) 
            sendPlaceData(res.searchaddress)
        })
        .catch(error => console.error('Error', error));
    }




  return ( 
    <>
        <FormControl fullWidth>
                    
                <PlacesAutocomplete
                    onSelect={handleSelect}
                    value={place || ''}
                    onChange={handleChange}
                    searchOptions={{
                        types: ["(regions)"],
                        componentRestrictions: { country: "IT" }
                      }}
                    
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                       
                        <TextField
                            variant={vari || "filled"}
                            fullWidth
                            inputProps="aria-label"
                            sx={{ fontSize: '1rem' }}
                         
                            name="places"
                            {...getInputProps({
                                placeholder,
                                className: `location-search-input ${simpleForm ? 'form-control form-sm' : ''}`,
                            })}
                            style={formStyle}
                        />
                        {(suggestions.length > 0 || loading) && (<>
                        <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading</div>}
                        {suggestions.map(suggestion =>  {
                            const cl = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                            // inline style for demonstration purpose
                            const st = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                            const { role, onMouseUp, onMouseDown, onMouseEnter, onMouseLeave, onTouchEnd, onTouchStart, onClick, className, style } = getSuggestionItemProps(suggestion, {
                                cl,
                                st,
                              })

                            return (
                            <button type="button" 
                            className={className}
                            style={style}
                            role={role}
                            onMouseDown={onMouseDown}
                            onMouseEnter={onMouseEnter} 
                            onMouseLeave={onMouseLeave} 
                            onTouchEnd={onTouchEnd}
                            onTouchStart={onTouchStart}
                            onClick={onClick}
                            onMouseUp={onMouseUp}

                            >{suggestion.description}</button>
                            );
                        }
                        )}

                        </div>
                        </>)}
                    </div>
                    )}
            </PlacesAutocomplete>
        </FormControl>
    </>
  );
};
export default PlacesAutocompleter;
