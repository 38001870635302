import React from 'react'
// import React, { useState, useCallback, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import MailIcon from '@mui/icons-material/Mail'
import NotificationsIcon from '@mui/icons-material/Notifications'
// import { FormattedMessage } from 'react-intl'

import { dumpProfile, setProfile, saveProfile } from 'slices/profileSlice'
import { setLoading, removeLoading } from 'slices/loadingSlice'

import ExImgClient from 'assets/images/ivana-square.jpg'
// import useHttp from '../../../../hooks/use-http'

const Sidebar = ({ profileData, currentPage, setSelectedPage, showMails, showNotifications }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userdata = JSON.parse(localStorage.getItem('userdata'))

  // const setLoading = value => {
  //   const payload = { type: value ? 'setLoading' : 'removeLoading' }
  //   dispatch(payload)
  // }

  const convertBase64 = file =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = error => {
        reject(error)
      }
    })

  const onUploadProfileImageChange = async event => {
    const file = event.target.files[0]
    const dataToSave = {
      ...profileData,
      estensionefoto: file.name.split('.').pop(),
      foto: await convertBase64(file)
    }
    await dispatch(setLoading)
    await dispatch(saveProfile(dataToSave))
    await dispatch(dumpProfile(profileData))
    dispatch(removeLoading)
    window.reload()
  }

  return (
    <>
      <div className="col-12 position-relative">
        

        <div className="text-center">
          <p>
            <img
              src={profileData?.pathfoto}
              alt="Profilo"
              className="avatar avatar-xxl border-radius-100 mt-5 bg-primary p-1"
            />
          </p>
          <h1 className="profileHeading">
            @ {profileData?.nome} {profileData?.cognome}
          </h1>
          <label
            className="text-primary font-weight-light lato cursor-pointer h6"
            onChange={onUploadProfileImageChange}
            htmlFor="profilo"
          >
            <input
              type="file"
              value=""
              id="profilo"
              name="profilo"
              // eslint-disable-next-line no-console
              onChange={console.log('FILE????')}
              accept=".png, .jpg, .jpeg"
              className="d-none"
            />
            <small>Cambia immagine di profilo</small>
          </label>
          
        </div>

        <ul className="nav-secondary">
          <li className={`${currentPage === 'profilo' && 'active'}`}>
            <button
              type="button"
              className="btn m-0 p-0 fs-7 box-shadow-none"
              onClick={() => setSelectedPage('profile')}
            >
              Profilo
            </button>
          </li>
          <li className={`${currentPage === 'freelancer-list' && 'active'}`}>
            <button
              type="button"
              className="btn m-0 p-0 fs-7 box-shadow-none"
              onClick={() => setSelectedPage('project-list')}
            >
              Progetti
            </button>
          </li>
          <li className={`${currentPage === 'pagamenti' && 'active'}`}>
            <button
              type="button"
              className="btn m-0 p-0 fs-7 box-shadow-none"
              onClick={() => setSelectedPage('pagamenti')}
            >
              Pagamenti
            </button>
          </li>
          {false ? (<>
          <li className={`${currentPage === 'prodotti-aggiuntivi' && 'active'}`}>
            <button
              type="button"
              className="btn m-0 p-0 fs-7 box-shadow-none"
              onClick={() => setSelectedPage('prodotti-aggiuntivi')}
            >
              Prodotti aggiuntivi
            </button>
          </li>
          </>) : null}
        </ul>
      </div>
    </>
  )
}

export default Sidebar
