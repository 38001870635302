import React, { useEffect, useState } from 'react'
// import {
//   Button,
//   TextField,
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControl,
//   FormLabel,
//   FormGroup,
//   FormControlLabel,
//   FormHelperText,
//   Checkbox,
// } from '@mui/material'
// import { PaymentElement } from '@stripe/react-stripe-js'

// @mui material components
import Create from '@mui/icons-material/Create'
// import Grid from '@mui/material/Grid'
// import Stack from '@mui/material/Stack'

// Material Kit 2 PRO React components
// import MKBox from 'components/MKBox'
// import MKTypography from 'components/MKTypography'
// import MKButton from 'components/MKButton'

// import Tabs from '@mui/material/Tabs'
// import Tab from '@mui/material/Tab'

// import { FormattedMessage } from 'react-intl'

import bonifico from 'assets/images/sitemeup/bonifico.png'
import stripe from 'assets/images/sitemeup/stripe.png'

import './css/Payments.css'

function Payments(props) {
  const { sendUpdate, freelanceData } = props

  const [isConnected, setIsConnected] = useState(false)
  // const [show, setShow] = useState(false)
  const [info, setInfo] = useState({
    strypo: freelanceData ? freelanceData.strypo : '',
    intestazione: freelanceData ? freelanceData.intestazione : '',
    IBAN: freelanceData ? freelanceData.IBAN : '',
    codiceswift: freelanceData ? freelanceData.codiceswift : ''
  })

  const [value, setValue] = useState(freelanceData.strypo ? 0 : 1)

  const setAttr = e => {
    const inf = { ...info }
    if (e.target.type !== 'checkbox') {
      inf[e.target.name] = e.target.value
      sendUpdate(e.target.name, e.target.value)
    } else {
      inf[e.target.name] = e.target.checked
      sendUpdate(e.target.name, e.target.checked)
    }
    setInfo(inf)
  }

  useEffect(()=>{
    const query = new URLSearchParams(window.location.search);
    if (query.get('code')) {
      setIsConnected(true)
      setValue(0)
    }
    console.log(freelanceData)
  },[])

  return (
    <>
      <section id="modalitaPagamento">
        <div className="row">
         

          <div className="col-lg-6 d-flex">
            <div className="container bg-secondary w-100 p-4 border-lg-radius-xl">
              <div className="pb-0 bg-secondary">
                <strong className="text-uppercase text-white">
                  METODO DI PAGAMENTO &nbsp;
                  <Create />
                </strong>
              </div>
              <div className="row px-md-2 px-lg-0">
                <h6 className="d-block w-100 text-gray">
                  Hai scelto di essere pagato tramite
                </h6>
                <div className="col-12 col-xl-7 py-2 px-1 d-flex mx-auto">
                  <button
                    onClick={() => setValue(1)}
                    className={`payment ${value ? 'active' : ''}`}
                    type="button"
                  >
                    <div> </div>
                    <img
                      src={bonifico}
                      className="img-payment"
                      alt="Bonifico Bancario"
                    />
                  </button>
                </div>
                <div className="col-12 col-xl-7 py-2 px-1 d-flex mx-auto">
                  <button
                    onClick={() => setValue(0)}
                    className={`payment ${value ? '' : 'active'}`}
                    type="button"
                  >
                    <div> </div>
                    <img src={stripe} className="img-payment" alt="Stripe" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 d-flex">
            <div className="container bg-lightblue w-100 p-4 border-lg-radius-xl">
              <div className="text-uppercase text-white pb-0">
                <strong>
                  INFORMAZIONI CONTABILI &nbsp;
                  <Create />
                </strong>
              </div>
              <div className="card-body text-white">
                {value === 0 ? (
                  <>
                    <div className="row align-items-center justify-content-center">
                      <span className="py-0 text-center">
                        { freelanceData?.strypo || isConnected ? (<>
                        
                        {!isConnected ? (<p className="text-white">Il tuo account è correttamente connesso a Stripe</p>) : (<><p className="text-white">Hai correttamente configurato il tuo account stripe</p></>)}
                        
                        <a
                          href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=https://localhost.smu-staging.click:3000/profilo`}
                          className="btn btn-light p-1 d-flex align-items-center mx-auto"
                        >
                          <strong
                            className="border-end border-1 fs-3 px-2"
                            style={{ color: '#635bff' }}
                          >
                            S
                          </strong>
                          <span className="px-3">Cambia account stripe</span>
                        </a>
                        </>) : (<>
                        <a
                          href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=https://localhost.smu-staging.click:3000/profilo`}
                          className="btn btn-light p-1 d-flex align-items-center mx-auto"
                        >
                          <strong
                            className="border-end border-1 fs-3 px-2"
                            style={{ color: '#635bff' }}
                          >
                            S
                          </strong>
                          <span className="px-3">Connetti account stripe</span>
                        </a>
                        </>)}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-12 py-0">
                        <label
                          htmlFor="intestazione"
                          className=" d-block w-100"
                        >
                          <span className="px-3 text-white">INTESTAZIONE</span>
                          <div className="input-group input-group-outline mb-3">
                            <input
                              className="form-control bg-white"
                              placeholder=""
                              type="text"
                              name="intestazione"
                              value={info.intestazione}
                              onChange={setAttr}
                            />
                          </div>
                        </label>
                      </div>
                      <div className="col-12 py-0">
                        <label htmlFor="iban" className=" d-block w-100">
                          <span className="px-3 text-white">IBAN</span>
                          <div className="input-group input-group-outline mb-3">
                            <input
                              className="form-control bg-white"
                              placeholder=""
                              type="text"
                              name="IBAN"
                              value={info.IBAN}
                              onChange={setAttr}
                            />
                          </div>
                        </label>
                      </div>
                      <div className="col-12 py-0">
                        <label htmlFor="bic" className=" d-block w-100">
                          <span className="px-3 text-white">BIC / SWIFT</span>
                          <div className="input-group input-group-outline mb-3">
                            <input
                              className="form-control bg-white"
                              placeholder=""
                              type="text"
                              name="codiceswift"
                              value={info.codiceswift}
                              onChange={setAttr}
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  )
}
export default Payments
