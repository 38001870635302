import React, { useRef, useState } from 'react'
// import React, { useState, useCallback, useEffect, useContext } from 'react'
import Input from '@mui/material/Input'
import { Select, MenuItem, TextField } from '@mui/material'
import PlacesAutocompleter from 'commoncomponets/Forms/PlacesAutocompleter'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

const NameProject = props => {
  const { project, projectTypes, sendProject, sendUpdate, handleNext, handleBack } = props
  const { titolo, descrizione, tiposito, projectType, localita, durata, siti, images } = project


const PlusBtn = ({onClick, className}) => (
  <button onClick={onClick} className={`plus-btn ${className}`} alt="" type="button">
    {' '}
    <AddIcon />{' '}
  </button>
)
const MinusBtn = ({onClick, className}) => (
  <button onClick={onClick} className={`plus-btn ${className}`} alt="" type="button">
    {' '}
    <RemoveIcon />{' '}
  </button>
)

  const convertBase64 = file =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = error => {
        reject(error)
      }
    })

    const isValidUrl = urlString=> {
	  	const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
	    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
	    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
	    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
	    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
	    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
	  return !!urlPattern.test(urlString);
	}

  const onUploadChange = async (event, i) => {
    const file = event.target.files[0]
    const dataToSave = {
      name:file.name,
      description:file.name,
      estensione: file.name.split('.').pop(),
      image: await convertBase64(file)
    }

    sendUpdate('images', images.map((ej, j)=> (j===i) ? dataToSave : ej) )

  }


  const updateProjectType = e => {
    sendProject({
      ...project,
      projectType: projectTypes.find(el => el.id === e.target.value) || {},
      profiles:
        projectTypes
          .find(el => el.id === e.target.value)
          ?.tipifreelance.map(ej => ({
            id_tipofreelance: ej.id_tipofreelance,
            obbligatorio: ej.obbligatorio,
            attivita: '',
            budget: 0,
            prezzonuovo: ej.prezzonuovo || 0,
            prezzorifacimento: ej.prezzorifacimento || 0,
            answers: []
          }))
          .filter(el => el.obbligatorio === 1) || []
    })
  }

  const updateValues = e => {
    sendUpdate(e.target.name, e.target.value)
  }

  return (
    <div className="col-11 mx-auto">

      <div className="row">
        <div className="col-12">
          <p className="fs-5 text-center">
            <h2>Racconta qualche dettaglio</h2>
          </p>
          
        </div>
      </div>


      <div className="row">
        <div className="col-12">
          <p className="text-uppercase fs-5">
            <b>Dai un nome al tuo progetto *</b>
          </p>
          <TextField
            variant="filled"
            name="titolo"
            placeholder="Es. Sito web personale"
            inputProps="aria-label"
            value={titolo}
            sx={{ fontSize: '1rem' }}
            onChange={updateValues}
            fullWidth
            required
            

          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12 mx-auto">
          <p className="text-uppercase fs-5">
            <b>Tipologia di progetto *</b>
          </p>
        </div>
        <div className="col-12 mx-auto">
          <Select
            name="projectType"
            inputProps="aria-label"
            value={projectType?.id || 0}
            sx={{ fontSize: '1rem' }}
            onChange={updateProjectType}
            fullWidth
            variant="filled"
            required
          >
            <MenuItem value="0">Seleziona una tipologia</MenuItem>
            {projectTypes.map(el => (
              <MenuItem key={`tip_${el.id}`} value={el.id}>
                {el.nome}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p className="text-uppercase fs-5">
            <b>Durata in giorni per la ricezione delle offerte *</b>
          </p>
          <TextField
            variant="filled"
            name="durata"
            type="number"
            placeholder="4"
            inputProps="aria-label"
            value={durata}
            sx={{ fontSize: '1rem' }}
            onChange={updateValues}
            fullWidth
            required
            

          />
        </div>
      </div>

      
      <div className="row mt-3">
        <div className="col-12">
          <p className="text-uppercase fs-5">
            <b>Dove vuoi sia sviluppato il tuo progetto *</b>
          </p>
          <PlacesAutocompleter 
            place={localita}
            sendPlaceData={(add)=>sendUpdate('localita', add)}
            placeholderr="Trova professionisti vicino a te che corrispondano alle tue esigenze"
          />
          
        </div>
      </div>

      

      <div className="row mt-3">
        <div className="col-12">
          <p className="text-uppercase fs-5">
            <b>Descrizione del progetto *</b>
          </p>
          <TextField
            variant="filled"
            name="descrizione"
            placeholder="Es. Un sito web con 25 articoli e 12 tipologie di pagine"
            inputProps="aria-label"
            value={descrizione}
            sx={{ fontSize: '1rem' }}
            onChange={updateValues}
            fullWidth
            required
            multiline
            rows={4}

          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p className="text-uppercase fs-5">
            <b>Link d&lsquo;ispirazione per il web designer</b>
          </p>
          {siti.map((el, i)=> (<>
            <div  className="d-flex align-items-center mt-2">
              <TextField
                variant="filled"
                name="siti"
                type="text"
                placeholder="es.: https://www.google.it"
                inputProps="aria-label"
                value={el?.sito}
                sx={{ fontSize: '1rem' }}
                onChange={(ev)=>sendUpdate('siti', siti.map((ej, j)=> (j===i) ? ({"sito":ev.target.value}) : ej) )}
                fullWidth
                required
              />
              <MinusBtn onClick={()=>sendUpdate('siti', siti.filter((ej, j)=>j !== i))} />
            </div>
            {el?.sito && (!isValidUrl(el?.sito) || el?.sito === '') ? (<>
            Inserisci un URL valido compreso di https:// </>) : null}
          </>))}
          <PlusBtn className="mt-3" onClick={()=>sendUpdate('siti', [...siti, {"sito":''}])} />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <p className="text-uppercase fs-5">
            <b>Carica documenti d&lsquo;ispirazione per il web designer (PDF, JPG, PNG)</b>
          </p>
          {images.map((el, i)=> (<>
            <div  className="d-flex align-items-center mt-2">
                <label className="d-flex align-items-center" htmlFor={`f${i}`}>
                  <span className="btn btn-primary">{el.name} - Cambia documento</span>
                  <div className="d-none">
                    <input
                      id={`f${i}`}
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, .pdf"
                      className="files-template d-none"
                      onChange={(ev)=>onUploadChange(ev, i)}
                    />
                  </div>
                </label>
                <MinusBtn onClick={()=>sendUpdate('images', images.filter((ej, j)=>j !== i))} />

            </div>
            
          </>))}
          <PlusBtn className="mt-3" onClick={()=>sendUpdate('images', [...images, {'name':`Seleziona un documento`, 'description':'', 'estensione':''}])} />
        </div>
      </div>


      <div className="row mt-3">
        <div className="col-6">
            <button
                  type="button"
                  className="btn btn-lg btn-primary ms-0 me-auto letter-spacing-1"
                  alt=""
                  onClick={() => handleBack()}
                >
                  <small>Indietro</small>
            </button>
        </div>
        <div className="col-6 text-right">
          <button
            type="button"
            className="btn btn-lg btn-primary letter-spacing-1 swpier-next"
            alt=""
            onClick={() => handleNext()}
            disabled={!localita || !projectType?.id || !titolo || !descrizione || !durata || (siti.length > 0 && siti.filter((ejs)=>!isValidUrl(ejs.sito) && ejs.sito !== '').length > 0)}
          >
            Avanti
          </button>

        </div>
      </div>
      
    </div>
  )
}

export default NameProject
