import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// @mui material components
import Grid from '@mui/material/Grid'
// import Stack from '@mui/material/Stack'

// Material Kit 2 PRO React components
import MKBox from 'components/MKBox'
// import MKTypography from 'components/MKTypography'
import MKButton from 'components/MKButton'

import headerimmage from 'assets/images/sitemeup/header_homepage.png'
import LoginForm from 'components/SMU/Login/LoginForm'
import { useDispatch } from 'react-redux'
import { clearProfile } from 'slices/profileSlice'

// import { FormattedMessage } from 'react-intl'

export default function Lead({ isLoggedIn, setIsLoggedIn }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)

  return (
    <>
      <MKBox display="flex" alignItems="center" className="bg-primary">
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={5}
            lg={6}
            className="d-lg-flex justify-content-center text-md-start text-center flex-column mt-sm-0 mt-2"
          >
            <MKBox
              component="img"
              src={headerimmage}
              alt="SiteMeup"
              className="mw-100"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            lg={6}
            className="d-flex flex-column justify-content-center align-items-start text-center text-md-start mt-2 mt-sm-0"
          >
            <h1 className="text-white">Sta arrivando Sitemeup!</h1>
            <div className="subtitle-hero text-white zilla">
              Il nuovo portale per creare siti web in wordpress con i migliori
              professionisti sul mercato.
            </div>
            <LoginForm
              show={show}
              sendLogin={() => {
                setIsLoggedIn(true)
                navigate('/profilo')
              }}
              sendLogout={() => {
                dispatch(clearProfile)
                setIsLoggedIn(false)
              }}
              close={() => setShow(false)}
            />
            <MKButton
              onClick={() =>
                !isLoggedIn ? setShow(true) : navigate('/profilo')
              }
              className="btn btn-white btn-lg my-4"
            >
              GUADAGNA COME WEB DESIGNER
            </MKButton>
          </Grid>
        </Grid>
      </MKBox>
    </>
  )
}
