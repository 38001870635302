import { useEffect, useState } from 'react'
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { SMULoading } from 'components/SMU';
import useHttp from 'hooks/use-http'
import Countdown from 'react-countdown';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { addDays, format } from 'date-fns'
import MKAvatar from 'components/MKAvatar'
import SMUModal from 'commoncomponets/Modal/SMUModal'
import ProgettoStandBy from 'assets/images/clessidra-in-corso.png'
import TemplateStandBy from 'assets/images/template-preview-no-image.png'
import I1 from 'assets/images/team-2.jpg'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
// import { Document, Page } from 'react-pdf'
import OffertaHeader from './sections/OffertaHeader'
import OffertaBody from './sections/OffertaBody'
import StatoLavoro from './sections/StatoLavoro'
import './DettagliOfferta.css'
import '../DettagliProgetto/DettagliProgetto.css'
import RecensioniFreelance from './components/RecensioniFreelance';

export default function DettagliOfferta({offerta}) {

  const [confirm, setConfirm] = useState(0)
  const { id, titolo, localita, offers, profiles, budget, skill, descrizione, templates, templatesceltodafreelance, prodotti, durata, transactionid} = offerta
  const [offerDetails, setOfferDetails] = useState({})
  const [requestHelp, setRequestHelp] = useState(false)
  const [freelances, setFreelances] = useState([])
  const [selectedFreelanceType, setSelectedFreelanceType] = useState(0)
  const [allFreelances, setAllFreelances] = useState([])
  const [fullOffers, setFullOffers] = useState(offers)
  const [bestTeamPrice, setBestTeamPrice] = useState(0)
  const [bestTeamAccepted, setBestTeamAccepted] = useState(false)
  const [bestTeamAllSigned, setBestTeamAllSigned] = useState(false)
  const [receivingRequestsModal, setReceivingRequestsModal] = useState(true)
  const [contractSignatureExplained, setContractSignatureExplained] = useState(false)
  const [signContractURL, setSignContractURL] = useState(null)

  const [editTeam, setEditTeam] = useState(false)

  const {
    sendRequest: sendOfferRequest,
    isLoading: isOfferLoading,
    error: offerError
  } = useHttp()

  const {
    sendRequest: sendUserRequest,
    isLoading: isUserRequestLoading,
    error: userRequestError
  } = useHttp()

  const {
    sendRequest: sendFreelanceTypesRequest,
    isLoading: isFreelanceTypesLoading
  } = useHttp()

  const {
    sendRequest: sendAllFreelancesRequest,
    isLoading: isAllFreelancesLoading
  } = useHttp()

  const manageFreelanceTypes = data => {
    setFreelances(data?.data || [])
  }

  const loadFreelanceTypes = () => {
    sendFreelanceTypesRequest({
      url: '/sitemeup/tipologiefreelance',
      manageData: manageFreelanceTypes
    })
  }


  const manageAllFreelances = data => {
    setAllFreelances(data?.data || [])
    loadFreelanceTypes()
  }

  const loadAllFreelances = () => {
    sendAllFreelancesRequest({
      url: '/sitemeup/freelances',
      manageData: manageAllFreelances
    })
  }

  const manageOrderDetails = (dt) => {
    setOfferDetails(dt.data)
  }

  const refreshDetails = () => {
    sendOfferRequest({
      url: `/sitemeup/order?id=${id}`,
      manageData: manageOrderDetails
    })
  }

  useEffect(()=>{
    refreshDetails()
    loadAllFreelances()

  }, [id])

  const manageUserRequest = (dt) => {
    if (dt.status === 'OK')
      refreshDetails()
  }

  const managePaidRequest = (dd, dt) => {
    window.open(dt.strype_session.url, '_blank', 'noreferrer')
    refreshDetails()
    
  }
  const managePaymentRequest = (dt) => {
    window.location.href = dt.strype_session.url;
    /* 
    window.open(dt.strype_session.url, '_blank', 'noreferrer')
    sendUserRequest({
      url: '/sitemeup/orderpayed',
      methodH:'POST',
      bodyH: {
        "id":id,
        "transactionid":"testok"
      },
      manageData: (dd)=>managePaidRequest(dd, dt)
    })
    */
  }

  const startPayment = () => {
    sendUserRequest({
      url: '/sitemeup/paymentwithurl',
      methodH:'POST',
      bodyH: {
        "payment_type":"card",
        "order_id":id,
        "description":titolo
      },
      manageData: managePaymentRequest
    })

  }

  const scrollToOfferte = () => {
    document.getElementById('offerte').scrollIntoView({
      behavior: 'smooth'
    });
  }

  const verifyOffer = (idx, dp) => {
    sendUserRequest({
      url: '/sitemeup/orderofferverified',
      methodH:'POST',
      bodyH: {
        id:idx,
        dopayment:dp
      },
      manageData: manageUserRequest
    })

    
  }

  const completeOffer = (idx) => {
    sendUserRequest({
      url: '/sitemeup/orderoffercompleted',
      methodH:'POST',
      bodyH: {
        id:idx
      },
      manageData: manageUserRequest
    })
    
  }

  const revokeApproval = (idx) => {
    sendUserRequest({
      url: '/sitemeup/orderofferremovefromteam',
      methodH:'POST',
      bodyH: {
        id:idx
      },
      manageData: manageUserRequest
    })
  }


  const addToTeam = (idx) => {
    sendUserRequest({
      url: '/sitemeup/orderofferaddtoteam',
      methodH:'POST',
      bodyH: {
        id:idx
      },
      manageData: manageUserRequest
    })
  }

  const [valutationID, setValutationID] = useState(null)
  const [valutationFullFreelance, setValutationFullFreelance] = useState(null)
  const [valutationFreelanceType, setValutationFreelanceType] = useState(null)


  const valutationStart = (idx, freelance, freetype) => {
    setValutationID(idx)
    setValutationFullFreelance(freelance)
    setValutationFreelanceType(freetype)
  }

  const acceptBestTeam = () => {
    setEditTeam(false)
    sendUserRequest({
      url: '/sitemeup/acceptbestteam',
      methodH:'POST',
      bodyH: {
        id
      },
      manageData: manageUserRequest
    })
  }

  const manageContractResponse = (dt) => {
    console.log(dt, 'Contract response')
    setSignContractURL(dt.offerta.chiavefirmacliente)
  }

  const signContract = (offer) => {
    setContractSignatureExplained(true)
    if (!offer.chiavefirmacliente) {
      sendUserRequest({
        url: '/sitemeup/startcontractsignature',
        methodH:'POST',
        bodyH: {
          id:offer.id
        },
        manageData: manageContractResponse
      })
    } else {
      setSignContractURL(offer.chiavefirmacliente)
    }
  }

  useEffect(()=>{

    if (offerDetails?.offers) {
      setFullOffers(
        offerDetails?.offers.map((el)=>({...el, id_tipo_freelance:allFreelances.find((ej)=>ej.id === el.id_freelance)?.id_tipologia}))
      )
    } else {
      setFullOffers(
        offers.map((el)=>({...el, id_tipo_freelance:allFreelances.find((ej)=>ej.id === el.id_freelance)?.id_tipologia}))
      )
    }

  }, [allFreelances, offers, offerDetails])

  useEffect(()=>{ 
    if (offerDetails.bestteam) {
      let priceTotal = 0;
      let allAccepted = true;
      let allSigned = true;
      Object.keys(offerDetails.bestteam).map( (btf)=> { 
        priceTotal += offerDetails.bestteam[btf].offer.offerta;
        if (offerDetails.bestteam[btf].offer.stato === 0) {
          allAccepted = false;
        }
        if (!offerDetails?.offers.find((ox)=>ox.id === offerDetails.bestteam[btf]?.offer?.id)?.flag_signed_cliente || !offerDetails?.offers.find((ox)=>ox.id === offerDetails.bestteam[btf]?.offer?.id)?.flag_signed_freelance) {
          allSigned = false;
        }
        return btf 
      } )
      
      setBestTeamAccepted(allAccepted)
      setBestTeamPrice(priceTotal)
      setBestTeamAllSigned(allSigned)
    }

  },[offerDetails])

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setReceivingRequestsModal(false)
      return '';
    } 

    return (<>
      <div className="d-flex justify-content-center align-items-center">
          <div className="pe-3 me-3 countdownValue">
            <div style={{fontSize:'80px', fontWeight:'900', color:'#FFB703', lineHeight:'80px'}}>{String(days).padStart(2, '0')}</div>
            <div>GIORNI</div>
          </div>
          <div className="pe-3 me-3 countdownValue">
            <div style={{fontSize:'80px', fontWeight:'900', color:'#FFB703', lineHeight:'80px'}}>{String(hours).padStart(2, '0')}</div>
            <div>ORE</div>
          </div>
          <div className="pe-3 me-3 countdownValue">
            <div style={{fontSize:'80px', fontWeight:'900', color:'#FFB703', lineHeight:'80px'}}>{String(minutes).padStart(2, '0')}</div>
            <div>MINUTI</div>
          </div>
          <div>
            <div style={{fontSize:'80px', fontWeight:'900', color:'#FFB703', lineHeight:'80px'}}>{String(seconds).padStart(2, '0')}</div>
            <div>SECONDI</div>
          </div>
      </div></>);
  }

  const [valutationFreelance, setValutationFreelance] = useState({
    "id_offerta":valutationID,
    "testo":`Recensione progetto: ${titolo}`,
    "parametro1_nome":"Tempi di esecuzione",
    "parametro1_stelle":0,
    "parametro2_nome":"Professionalità",
    "parametro2_stelle":0,
    "parametro3_nome":"Creatività",
    "parametro3_stelle":0,
    "parametro4_nome":"Altro criterio 1",
    "parametro4_stelle":0,
    "parametro5_nome":"Altro criterio 2",
    "parametro5_stelle":0,
    "parametro6_nome":"Altro criterio 3",
    "parametro6_stelle":0,
    "parametro7_nome":"Altro criterio 4",
    "parametro7_stelle":0
  })

  const sendValutationRequest = () => {
    if (valutationID) {
      const idv = valutationID;
      setValutationID(null)
      sendUserRequest({
        url: '/sitemeup/valutazionefreelancesave',
        methodH:'PUT',
        bodyH: {
          ...valutationFreelance,
          id_offerta:idv
        },
        manageData: (dt)=> {
          manageUserRequest(dt)
          setValutationFreelance({
            "id_offerta":valutationID,
            "testo":`Recensione progetto: ${titolo}`,
            "parametro1_nome":"Tempi di esecuzione",
            "parametro1_stelle":0,
            "parametro2_nome":"Professionalità",
            "parametro2_stelle":0,
            "parametro3_nome":"Creatività",
            "parametro3_stelle":0,
            "parametro4_nome":"Altro criterio 1",
            "parametro4_stelle":0,
            "parametro5_nome":"Altro criterio 2",
            "parametro5_stelle":0,
            "parametro6_nome":"Altro criterio 3",
            "parametro6_stelle":0,
            "parametro7_nome":"Altro criterio 4",
            "parametro7_stelle":0
          })
        }
      })
    }
  }

  const [paymentDialog, setPaymentDialog] = useState(false)
  const [offerDetail, setOfferDetail] = useState(null)


  return (<>

    <SMUModal 
      show={requestHelp}
      message={(<><h3>Compila il seguente form per richiedere aiuto al Project Manager.</h3></>)}
      clear={()=>setRequestHelp(false)}
    />
    
    <Dialog fullWidth maxWidth="lg" open={offerDetail} onClose={()=>{console.log(offerDetail); setOfferDetail(null)}}>

            <DialogTitle>Dettagli offerta ricevuta</DialogTitle>
            <DialogContent>
              <div className="row">
                {offerDetail?.template?.id ? (<>
                  <div className="col-md-4">
                      <div>
                        <p><strong>Template scelto</strong>: {offerDetail?.template?.nome}</p>
                        <img src={offerDetail?.template?.immagine} alt={offerDetail?.template?.nome} className="img-fluid" />
                      </div>
                      <div className="row">

                        {Object.keys(offerDetail?.images).map((kk)=> {

                            const elm = offerDetail.images[kk][0];
                            return (<div className="col-6 mt-3">
                              <div className="bg-secondary text-white text-center">{kk}</div>
                              <img src={elm.imagepath} alt={kk} className="img-fluid" />
                              
                            </div>)

                        })}
                      </div>
                  </div>
                </>) : null}
                <div className="col-md-8">
                  <p><strong>Descrizione offerta</strong></p>
                  <p>{offerDetail?.descrizione}</p>
                  <div className="row">
                      <div className="col-6">
                        
                        <p>{offerDetail?.giorni} gg. lavorativi</p>
                      </div>
                      <div className="col-6">
                        <p><strong>€{offerDetail?.offerta}</strong></p>
                      </div>
                  </div>
                  <div><p><strong>@{offerDetail?.freelance}</strong></p>
                  <a href={`/freelancer/${offerDetail?.id_freelance}`} rel="noreferrer" target="_blank" className="btn btn-primary">Vedi profilo freelance</a>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
 
    <Dialog fullWidth maxWidth="xl" open={signContractURL} onClose={()=>{setSignContractURL(null); refreshDetails()}}>
      <iframe title="YouSign - Firma contratto" width="100%" height="600" border="0" src={`https://staging-app.yousign.com/procedure/sign?members=${signContractURL}`} />
    </Dialog>

    <Dialog fullWidth maxWidth="xl" open={valutationID} onClose={()=>setValutationID(null)}>
      <DialogContent>
        {valutationID ? (<>
        <RecensioniFreelance valutation={valutationFreelance} freelance={valutationFullFreelance} freetype={valutationFreelanceType} onChange={(e)=>setValutationFreelance(e)} sendValutation={sendValutationRequest} />
        </>) : null}
      </DialogContent>
    </Dialog>

          <Dialog fullWidth maxWidth="lg" open={paymentDialog} onClose={()=>setPaymentDialog(false)}>

            <DialogTitle>Paga il progetto</DialogTitle>
            <DialogContent>

              <table className="table table-striped">

                <tr>

                  <th>Servizio</th>
                  <th>Costo</th>
                </tr>
                <tr>
                  <td>Importo Freelance accettati</td>
                  <td>€ {bestTeamPrice.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Importo IVA</td>
                  <td>€ {(offerDetails?.amounts?.importoiva || 0).toFixed(2)}</td>
                </tr> 
                <tr>
                  <td>Costo template</td>
                  <td>€ {(offerDetails?.amounts?.costotemplate || 0).toFixed(2)}</td>
                </tr> 
                <tr>
                  <td>Commissione cliente</td>
                  <td>€ {(offerDetails?.amounts?.importocommissionecliente || 0).toFixed(2)}</td>
                </tr> 
                <tr>
                  <td>Ritenuta</td>
                  <td>- € {(offerDetails?.amounts?.importoritenuta || 0).toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Totale da pagare</td>
                  <td>€ {((bestTeamPrice || 0) + (offerDetails?.amounts?.importoiva || 0) + (offerDetails?.amounts?.costotemplate || 0) + (offerDetails?.amounts?.importocommissionecliente || 0)
                   - (offerDetails?.amounts?.importoritenuta || 0)).toFixed(2)}</td>
                </tr> 
              </table>

            </DialogContent>
            <DialogActions>
              <button type="button" className="btn btn-primary mb-0" onClick={()=>{setPaymentDialog(false); startPayment()}}>PAGA ORA</button>
            </DialogActions>
          </Dialog>

    <SMULoading show={isUserRequestLoading || isOfferLoading} />

    <div className=" makeOfferProject">
      <div className="project-name">
        <div className="container">
          <div className="row align-items-center px-3">
            <div className="col-md-9">
              <span style={{fontSize:'0.75em', color:'#CEE0ED'}}><LocationOnIcon fontSize="small" color="#CEE0ED" /> {localita}</span><br />
              <h1>{titolo}</h1>
            </div>
            <div className="col-12 col-md-3">
              <section className="budget text-center">
                STIMA COSTO
                <span>€ {budget}</span>
                <span className="gold-background">{offerDetails?.offers?.length || offers?.length} Offerte</span>
              </section>
            </div>

          </div>
        </div>
      </div>
  </div>
  <div className="dettagliOfferta">
    <div className="container pt-3 ">
      <div className="row px-3">

        <div className="col-md-4">
          {!templatesceltodafreelance ? (<>
            {templates?.length > 0 ? (<>
              <img src={TemplateStandBy} alt="Template scelto" className="img-fluid" />
            </>) : (<>
              <button
                type="button"
                className="btn btn-primary"
              >
                Scegli template
              </button>
            </>)}
          </>) : (<>
          {offerDetails?.offers?.find((el)=>el.stato > 0 && el.stato < 7 && el?.template?.id) ? (<>
            <div className="template-scelto pb-4">
              <p>{offerDetails?.offers?.find((el)=>el.stato > 0 && el.stato < 7 && el?.template?.id)?.template?.nome}</p>
              <img src={offerDetails?.offers?.find((el)=>el.stato > 0 && el.stato < 7 && el?.template?.id)?.template?.immagine} alt="Template scelto" className="img-fluid" />
            </div>

          </>) : (<>
            Template scelto da freelance
            </>)}
          </>)}
        </div>
        <div className="col-md-8">
          <div className="bl-b-1 ps-4">
            <p><b className="fs-6 w-100 text-uppercase">DESCRIZIONE DEL PROGETTO</b></p>
            <p>{descrizione}</p>

            <p>
              <b className="fs-6 w-100 text-uppercase">Skills richieste:</b></p>
              <div className="list-skills row">
                <div className="col-lg-3 col-md-4 col-6 p-1">
                  <div className="skill text-truncate">BASE</div>
                </div>
              {skill.map(skil => (
                <div key={skil.id} className="col-lg-3 col-md-4 col-6 p-1">
                  <div className="skill text-truncate">{skil.name}</div>
                </div>
              ))}
              </div>
            </div>
        </div>


      </div>
    </div>


    { isOfferLoading ? (<>
    <p className="text-center">Caricamento offerte in corso</p>
    </>) : (<>

  <div className="detailsContainer position-relative w-100 overflow-hidden" style={{minHeight:'800px'}}>

    {receivingRequestsModal ? (<>
    <div style={{ width:'100%', height:'100%', position:'absolute', top:'0', left:'0', background:'rgba(255,255,255,.5)', zIndex:2}} />
    <div className="d-flex justify-content-center" style={{ width:'100%', position:'absolute', top:'0', left:'0', paddingTop:'60px', zIndex:4}}>
      <div style={{background:'#013A63', padding:'60px 40px', color:'#FFF', height:'auto',  width:'70%', borderRadius:20}}>

        <div className="text-center">
          <img src={ProgettoStandBy} alt="Requests" width="200" />
          <h4 className="text-white" style={{textTransform:'none', fontSize:'1.6em'}}>Stiamo ricevendo le tue offerte per preparare<br />il team migliore per le tue esigenze</h4>
          {offerta?.created_at ? (<>
          <Countdown renderer={renderer} date={addDays(new Date(offerta?.created_at), durata)} />
          </>) : null}

      {offerDetails?.offers?.length > 0 ? (<>
        <p><button type="button" className="btn text-white mt-3" onClick={()=>setReceivingRequestsModal(false)}>Visualizza le offerte</button></p>
      </>) : (<>
        <p><button type="button" className="btn text-white mt-3" onClick={()=>setReceivingRequestsModal(false)}>Non hai ancora ricevuto offerte</button></p>
      </>)}
         
        </div>

      </div>
    </div>
    </>) : null}
    

    <div className={receivingRequestsModal ? 'blurred pb-5' : 'pb-5'}>


    {offerDetails?.bestteam ? (<>
    <section className="bg-secondary p-3">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center">
                <span className="flex-auto text-nowrap" style={{fontSize:'1.5em', color:'#FFB703', fontWeight:900}}>
                  Il tuo team /
                </span>
                <span className="text-white ms-2" style={{fontSize:'0.8em'}}>
                  {offerDetails.stato === 0 ? (<>
                  Questi sono i professionisti che abbiamo automaticamente per te. Puoi confermarli o modificare la tua squadra prima di attivarla ufficialmente.
                  </>) : (<>Gestisci il tuo team</>)}
                </span>
            </div>
                
          </div>
        </div>
        <div className="mt-3 row align-items-center">
          <div className="col-md-7">
            <div className="d-flex align-items-center">
              <MKAvatar
                src={I1}
                alt="Project Manager"
                shadow="md"
                variant="rounded"
              />
              <p className="ms-3 mb-0 text-white">
                PROJECT MANAGER (SITEMEUP)<br />
                <span className="text-primary">Alessandro Giansante - Roma</span>
              </p>
            </div>
          </div>
          <div className="col-md-5 text-md-end">
            <div className="d-flex align-items-center justify-content-end">
              <span className="d-inline-block text-white text-small text-center p-2 me-2" style={{background:'rgba(0,0,0,.18)', fontSize:'0.6em'}}>
              Qualche dubbio sulla tua squadra?<br />
            Chiedi al tuo project manager
              </span>
              <button type="button" className="btn btn-yellow text-uppercase mb-0" onClick={()=>setRequestHelp(true)}>
                Richiedi Aiuto
              </button>
            </div>
          </div>
          <div className="col-12 mb-3">
            <hr style={{borderColor:'#fff', color:'#fff', backgroundColor:'#fff'}} />
          </div>
        </div>


        {offerDetails.stato === 0 ? (<>

        { Object.keys(offerDetails.bestteam).map( (btf)=> (<>
        <div className="row mb-2">
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <MKAvatar
                src={offerDetails.bestteam[btf]?.pathfoto || ''}
                alt={btf}
                shadow="md"
                variant="rounded"
              />
              <p className="ms-3 mb-0 text-white">
                {btf.toUpperCase()}<br />
                <span className="text-primary">{offerDetails.bestteam[btf]?.nome} {offerDetails.bestteam[btf]?.cognome}</span>
              </p>
            </div>
          </div>
          { offerDetails.bestteam[btf]?.approved && offerDetails.bestteam[btf]?.offer?.stato > 0 ? (<>
            <div className="col-md-6 text-md-end">
            <div className="d-flex align-items-center justify-content-end">
              <button type="button" disabled  className="d-inline-block text-white small text-center p-2 me-4 btn btn-primary"  style={{fontSize:'0.7em'}} onClick={()=>verifyOffer(offerDetails.bestteam[btf]?.id, 0)}>
                Paga ora
              </button>
              {offerDetails?.offers.find((ox)=>ox.id === offerDetails.bestteam[btf]?.offer?.id)?.flag_signed_freelance && offerDetails?.offers.find((ox)=>ox.id === offerDetails.bestteam[btf]?.offer?.id)?.flag_signed_cliente ? (<>
              <a href={offerDetails?.offers.find((ox)=>ox.id === offerDetails.bestteam[btf]?.offer?.id)?.contrattopath} rel="noreferrer" target="_blank" className="d-inline-block text-white small text-center p-2 me-4 btn "  style={{fontSize:'0.7em', color:'#fff !important'}}>
                Scarica contratto
              </a>
              </>) : (<>
                
                {!offerDetails.offers.find((ox)=>ox.id === offerDetails.bestteam[btf]?.offer?.id)?.flag_signed_freelance ? (<>
                  <button type="button" disabled className="d-inline-block text-white small text-center p-2 me-4 btn"  style={{fontSize:'0.7em'}}>
                      In firma dal Freelance
                    </button>
                  
                </>) : (<>
                
                  {!offerDetails?.offers.find((ox)=>ox.id === offerDetails.bestteam[btf]?.offer?.id)?.flag_signed_cliente ? (<>
                    <button type="button" className="d-inline-block text-white small text-center p-2 me-4 btn btn-white" onClick={()=>signContract(offerDetails.bestteam[btf]?.offer)} style={{fontSize:'0.7em'}}>
                      Firma contratto
                    </button>
                  </>) : null}


                </>)}
              
              </>)}

              {offerDetails.bestteam[btf]?.offer?.flag_signed_freelance && offerDetails.bestteam[btf]?.offer?.flag_signed_cliente ? (<>
                <button disabled type="button" className="d-inline-block text-white small text-center p-2 me-4 btn btn-primary"  style={{fontSize:'0.7em'}} onClick={()=>completeOffer(offerDetails.bestteam[btf]?.id)}>
                Completa l&lsquo;incarico
              </button>
              </>) : (<>
              <button type="button" className="d-inline-block text-white small text-center p-2 me-4 btn btn-primary"  style={{fontSize:'0.7em'}} onClick={()=>revokeApproval(offerDetails.bestteam[btf]?.id)}>
                Revoca l&lsquo;incarico
              </button>
              </>)}
              
            </div>
          </div> 

          
          </>) : (<>
          <div className="col-md-6 text-md-end">
            <div className="d-flex align-items-center justify-content-end">
              {!editTeam ? (<>
              <button type="button" onClick={()=>setOfferDetail(offerDetails?.offers?.find((eee)=>eee.id === offerDetails.bestteam[btf]?.offer.id))}  className="d-inline-block text-white small text-center p-2 me-2 btn"  style={{fontSize:'0.7em'}}>
                Vedi dettagli
              </button>
              <span className="text-white bl-1 ps-2">
                <strong>€ {offerDetails.bestteam[btf]?.offer?.offerta.toFixed(2)}</strong><br />
                <span className="small" style={{fontSize:'0.7em'}}>{offerDetails.bestteam[btf]?.offer?.giorni} gg. lavorativi</span>
              </span>
              </>) : (<>
              
                <button type="button" className="d-inline-block text-white small text-center p-2 me-4 btn mb-0" onClick={()=>setOfferDetail(offerDetails?.offers?.find((eee)=>eee.id === offerDetails.bestteam[btf]?.offer.id))} style={{fontSize:'0.7em'}}>
                  Vedi dettagli
                </button>
                <div className="bl-1 p-1 d-flex align-items-center" style={{background:'#FFF', borderRadius:12}}>
                  <strong>€ {offerDetails.bestteam[btf]?.offer?.offerta.toFixed(2)}</strong>
                  <span className="small ms-1" style={{fontSize:'0.7em'}}>in {offerDetails.bestteam[btf]?.offer?.giorni} gg. lavorativi</span>
                  <button type="button" className="btn mb-0 ms-2" style={{background:'#CEE0ED', fontSize:'0.85em'}} onClick={scrollToOfferte}>Cambia</button>
                </div>
              
              </>)}

            </div>
          </div> 
          </>)}
      </div>
      </>) ) }

      

          <div className="row">
            <div className="col-12 mb-3">
              <hr style={{borderColor:'#fff', color:'#fff', backgroundColor:'#fff'}} />
            </div>
        </div>
        <div className="row align-items-center">
         
          <div className="col-12 text-md-end">
            <div className="d-flex align-items-center justify-content-end">

            { bestTeamAccepted || offerDetails?.offers?.filter((eof)=>eof.stato > 0 && eof.stato !== 7)?.length === profiles?.length ? (<>
              {offerDetails?.offers?.filter((eof)=>eof.stato > 0 && eof.stato !== 7)?.length === profiles?.length ? (<>

              {!offerDetails.transactionid || offerDetails?.transactionid === '' ? (<>
                <button type="button" disabled={!bestTeamAllSigned} className="btn btn-primary text-uppercase mb-0 me-3" onClick={()=>setPaymentDialog(true)}>
                  Paga il progetto
                </button>
                
                </>) : null}
                <span className="d-inline-block p-2" style={{color:'#FFB703', background:'rgba(0,0,0,.3)'}}>
                  € {bestTeamPrice.toFixed(2)}
                </span>
              </>) : null}
            </>) : (<>

             

              {Object.keys(offerDetails.bestteam).length === profiles?.length? (<>
              {!editTeam ? (<>
              <button type="button" className="btn btn-gray text-primary text-uppercase mb-0 me-3" onClick={()=>setEditTeam(true)}>
                Modifica
              </button>
              </>) : null}
              
              
              <button type="button" className="btn btn-primary text-uppercase mb-0 me-3" onClick={()=>acceptBestTeam()}>
                Conferma la squadra
              </button>
              <span className="d-inline-block p-2" style={{color:'#FFB703', background:'rgba(0,0,0,.3)'}}>
                € {bestTeamPrice.toFixed(2)}
              </span>
              </>) : (<>
                <span className="d-inline-block p-2" style={{color:'#FFB703', background:'rgba(0,0,0,.3)'}}>
                In attesa delle offerte
              </span>
              </>)}
              

              </>)}

            </div>
          </div>
        </div>
        </>) : (<>
        
        { offerDetails.offers.filter((el)=>el.stato >= 3 && el.stato !== 7).map((of)=> {

          const freelance = allFreelances.find((el)=>el.id === of.id_freelance)
          const freetype = freelances.find((el)=>el.id === freelance?.id_tipologia)

          return (<>
            <div className="row mb-2">
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <MKAvatar
                    src={freelance?.pathfoto || ''}
                    alt={`${freelance?.nome} ${freelance?.cognome}`}
                    shadow="md"
                    variant="rounded"
                  />
                  <p className="ms-3 mb-0 text-white">
                    {`${freetype?.name?.toUpperCase()}`}<br />
                    <span className="text-primary">{freelance?.nome} {freelance?.cognome}</span>
                  </p>
                </div>
              </div>
             
                <div className="col-md-6 text-md-end">
                  <div className="d-flex align-items-center justify-content-end">
                   

                    <button type="button" className="d-inline-block btn-white text-white small text-center p-2 me-4 btn"  style={{fontSize:'0.7em'}}>
                      Scarica contratto
                    </button>

                    <button type="button" disabled={of?.stato !== 5 || of?.stato === 6} className="d-inline-block text-white small text-center p-2 me-4 btn btn-primary"  style={{fontSize:'0.7em'}} onClick={()=>verifyOffer(of?.id, 0)}>
                     {of?.stato === 6 ? (<>Incarico completato!</>) : (<>Completa l&lsquo;incarico</>)}
                    </button>

                    {of?.stato === 6 ? (<>
                    <button type="button" disabled={of?.stato !== 6 || of?.valutazioni?.length > 0} className="d-inline-block text-white small text-center p-2 me-4 btn btn-primary"  style={{fontSize:'0.7em'}} onClick={()=>valutationStart(of?.id, freelance, freetype)}>
                      {of?.valutazioni?.length > 0 ? 'Hai lasciato una recensione' : 'Recensisci freelance'}
                    </button>
                    </>) : null}
                    
                  </div>
                </div>
            </div>
          </>)

        }) }
        
        
        
        
        
        
        
        </>)}
      </div>
    </section>
    </>) : null}

    {!isFreelanceTypesLoading && !isAllFreelancesLoading ? (<>
    {offerDetails.stato === 0 ? (<>
          <section id="offerte" className={!editTeam ? 'blurred position-relative' : "position-relative"}>
            {!editTeam ? (<div style={{position:'absolute', width:'100%', height:'100%', background:'rgba(255,255,255,.7)', top:0, left:0}} />) : null}
            <div className="container">
                <div className="row">
                    <section className="col-12 col-md-9 list-skills mt-3 mt-md-0 text-center text-md-start">
                      <div className='row'>
                      <div className="col-12 d-flex">
                        <button type="button" className="btn skill text-truncate me-2" disabled 
                                style={{ background: '#EDF1F4', color: '#CEE0ED', borderBottomLeftRadius:0, borderBottomRightRadius:0 }}
                        >
                          Project Manager
                        </button>
                      
                      {profiles.map(el => (<>
                          {el.id_tipofreelance === selectedFreelanceType ? (
                            <>
                              <button type="button" className="btn me-2 skill text-truncate" disabled 
                                style={{ background: '#0565a2', color: '#FFF', opacity:1, borderBottomLeftRadius:0, borderBottomRightRadius:0 }}
                              >
                                {freelances.find(ej => ej.id === el.id_tipofreelance)?.name}
                              </button>
                            </>
                          ) : (
                            <>
                              <button type="button" onClick={(e)=>setSelectedFreelanceType(el.id_tipofreelance)} className="btn me-2 skill text-truncate" style={{borderBottomLeftRadius:0, borderBottomRightRadius:0, cursor:"pointer"}}>
                                {freelances.find(ej => ej.id === el.id_tipofreelance)?.name}
                              </button>
                            </>
                          )}
                      </>))}
                      </div>
                    </div>
                    </section>
              </div>



              {freelances.find(ej => ej.id === selectedFreelanceType) ? (<>

  <div className="row">


                <div className="col-8">
                <p><b className="fs-6 w-100 text-uppercase">{fullOffers.filter((el)=>el.id_tipo_freelance === selectedFreelanceType).length} OFFERTE RICEVUTE</b></p>
                  
                  <table className="table">
                    <tr>
                      <th>Data</th>
                      <th>Freelance</th>
                      <th>Offerta</th>
                      <th>&nbsp;</th>
                      <th>Accetta</th>
                    </tr>
                  
                  {fullOffers.filter((el)=>el.id_tipo_freelance === selectedFreelanceType).map((el)=>(<>
                    <tr>
                      <td>{format(new Date(el.created_at), 'dd/MM/yyyy')}</td>
                      <td>@{el.freelance}</td>
                      <td>€ {el.offerta}</td>
                      <td><button type="button" className="btn"  onClick={()=>setOfferDetail(el)}>Vedi</button></td>
                      <td>
                        {el.stato !== 7 ? (<>
                        <button type="button" className="btn btn-primary" onClick={()=>addToTeam(el.id)}>Scegli</button>
                        </>) : (<>Revocato</>)}
                      </td>
                    </tr>
                  </>))}
                  </table>
                </div>
                <div className="col-4">
                  <div className="row">
                    <div className="col-12">
                      Budget stimato per il {freelances.find(ej => ej.id === selectedFreelanceType)?.name}<br />
                      <span className="text-price text-primary">€ {profiles.find((el)=>el.id_tipofreelance === selectedFreelanceType)?.budget.toFixed(2) || 0}</span>
                      <hr style={{color:'#0565a2', opacity:1}} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row">
                      <h5>Specifiche per il progetto</h5>
                      <p><strong>Descrizione attività:</strong><br />
                      {profiles.find(ej => ej.id_tipofreelance === selectedFreelanceType)?.attivita}</p>

                      {Object.keys(profiles.find(ej => ej.id_tipofreelance === selectedFreelanceType)?.answers).map((el)=>
                        (<>
                          <h6>{el}</h6>
                          {profiles.find(ej => ej.id_tipofreelance === selectedFreelanceType)?.answers[el].map((ej)=>(<>
                          <p>
                            <strong>{ej?.text}</strong><br />
                            {ej?.answer?.answer}
                          </p>
                          </>))}
                        </>)

                      )}
                    </div>
                </div>
              </div>


              </div>

            </>) : null}


            </div>
          </section>

          </>) : null}

    </>) : (<>
      {offerDetails.stato === 0 ? (<>
        <p className="text-center">Caricamento di tutte le offerte</p>
      </>) : null }
    </>)}



    <section id="prodotti" className="py-3" style={{background:'#CEE0ED'}}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p><b className="fs-6 w-100 text-uppercase">PRODOTTI AGGIUNTIVI</b></p>
            </div>
          </div>
          {!prodotti || prodotti.length === 0 ? (<>
          <div className="row">
            <div className="col-12">
              <p>Non hai acquistato prodotti aggiuntivi per questo progetto.</p>    
            </div>
          </div></>) : (<>
          
          {prodotti.map((el)=>(<>
          <div className="row">
            <div className="col-8">
              {el.nome || 'Prodotto sconosciuto'}
            </div>
            <div className="col-4">
              € {(el.prezzo || 0).toFixed(0)}
            </div>
          </div>
          </>))}

          </>)}
        </div>

        
    </section>

    </div>

    </div> { /* dettagliOfferta */ }
    </>)}
  </div>
</>)
}
