import { useEffect, useState } from 'react'
import useHttp from 'hooks/use-http'
import { format } from 'date-fns'
import MyProjects from 'components/SMU/MyProjects'

import { Telegram } from '@mui/icons-material'
import { SMULoading } from 'components/SMU'
import { Link } from 'react-router-dom'

export default function StoricoProgetti({ profileData, onClickEvent, onLoad }) {

  const { id } = profileData
  const [projectList, setProjectList] = useState([])
  const [activeProjects, setActiveProjects] = useState([])
  const [previousProjects, setPreviousProjects] = useState([])
  const [offeringProjects, setOfferingProjects] = useState([])

  const {
    sendRequest: sendProjectListRequest,
    isLoading: isProjectsLoading,
    error: projectsLoadingError
  } = useHttp()

  const manageOrders = (res) => {
    console.log(id, 'ID FREELANCE')
    console.log(res)
    

    setActiveProjects(
      res.data.filter((el)=>el.offers.find((ej)=>ej.id_freelance === id) && el.offers.find((ej)=>ej.id_freelance === id).stato > 0 && el.offers.find((ej)=>ej.id_freelance === id).stato < 6).map((el)=>({...el, dateStart:format(new Date(el.created_at), 'dd-MM-yyyy'), name:el.titolo, status:el.stato}) )
    )

    setOfferingProjects(
      res.data.filter((el)=>el.offers.find((ej)=>ej.id_freelance === id) && el.offers.find((ej)=>ej.id_freelance === id).stato === 0).map((el)=>({...el, dateStart:format(new Date(el.created_at), 'dd-MM-yyyy'), name:el.titolo, status:el.stato}) )
    )

    setPreviousProjects(
      res.data.filter((el)=>el.offers.find((ej)=>ej.id_freelance === id) && el.offers.find((ej)=>ej.id_freelance === id).stato === 6).map((el)=>({...el, dateStart:format(new Date(el.created_at), 'dd-MM-yyyy'), name:el.titolo, status:el.stato}) )
    )

  }

  const refreshList = () => {
    sendProjectListRequest({
      url: '/sitemeup/ordersfreelance',
      manageData: manageOrders
    })
  }

  useEffect(()=>{
    refreshList()
  }, [id])

  return (<>
    
    <div className="storico-progetti lato p-3">

    <SMULoading show={isProjectsLoading} />
    {isProjectsLoading ? (<><p className="title">Caricamento dei tuoi progetti</p></>) : (<>
      
      {activeProjects.length > 0 ? (<>
        <span className="title">Progetti in corso</span>
      <MyProjects list={activeProjects} onClickEvent={onClickEvent} />
      </>) : (<>
        <div className='text-center mb-5 mt-3'>
        <h2>Non hai progetti in corso</h2>
        <Link to="/progetti" className="btn btn-primary">Fai un&lsquo;offerta</Link>
      </div>
      </>) }
      

      <span className="title">Progetti con tue offerte</span>
      <MyProjects completed list={offeringProjects} />

      <span className="title">Storico progetti</span>
      <MyProjects
        list={previousProjects}
        completed
        onClickEvent={onClickEvent}
      />
      </>)}
    </div>
    </> )
}
