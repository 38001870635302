/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { AuthContext } from 'store/auth-context'
// import { useNavigate } from 'react-router-dom'
import { setLoading, removeLoading } from 'slices/loadingSlice'
import { dumpProfile, selectProfile } from 'slices/profileSlice'
import { dumpUser, selectIsEmailVerified } from 'slices/userSlice'
import useHttp from 'hooks/use-http'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import SMUNavbar from 'commoncomponets/Navbars/SMUNavbar_main'
import SMUFooter from 'commoncomponets/Footer/SMUFooter'
import DettagliOffertaFreelance from 'pagesitemeup/DettagliOfferta/DettagliOffertaFreelance'
import DettagliOfferta from 'pagesitemeup/DettagliOfferta'
import footerRoutes from 'footer.routes'
import SMUModal from 'commoncomponets/Modal/SMUModal'
import Notifiche from 'pagesitemeup/Notifiche'
import ClientPayments from './Client/components/ClientPayments'
import ProfileBody from './components/ProfileBody'
import Sidebar from './components/Sidebar'
import Drawer from './components/RightDrawer'
import Wallet from '../Wallet/Wallet'
import Portfolio from '../Portfolio/Portfolio'
import StoricoProgetti from './sections/Progetti'
import StoricoProgettiCliente from './Client/sections/Progetti'
import SMUDrawer from './Client/components/Drawer'
import ClientSidebar from './Client/components/Sidebar'


import 'pagesitemeup/Profilo/components/LeftProfileNavigation.css'
import 'pagesitemeup/Profilo/Profilo.css'






const Profilo = () => {
  const { authState } = useContext(AuthContext)
  const profileData = useSelector(selectProfile)
  const searchParams = useSearchParams()
  const dispatch = useDispatch()

  const [boolWallet, setBoolWallet] = useState(false)
  const [boolPortfolio, setBoolPortfolio] = useState(false)
  const [clientPanel, setClientPanel] = useState(0)
  const [selectedPage, setSelectedPage] = useState('profile')
  const [tabPage, setTabPage] = useState(0)
  const [selectedData, setSelectedData] = useState({})
  const [notifications, setNotifications] = useState([])
  const [userID, setUserID] = useState()
  

  const handleSelectProject = (s, p) => {
    setSelectedPage(s)
    setSelectedData(p)
  }

  console.log(profileData)  

  const {
    sendRequest: sendStripeConnection,
    isLoading: isLoadingStripeConnection,
    error: stripeConnectionError
  } = useHttp()

  
  const {
    sendRequest: checkNotifications,
    isLoading: isNotificationsLoading,
    error: notificationsError
  } = useHttp()
  
  const manageNotificationResponse = (dt) => {
    setNotifications(dt.data)
  }

  useEffect(() => {
    async function getProfile(data) {
      dispatch(setLoading())
      // console.log('APERTURAAAAAA')
      setUserID(data.id)
      const { tipo } = data
      if (tipo === 'cliente') setClientPanel(1)
      // console.log('AUTH', data)
      await dispatch(dumpProfile(data))
      dispatch(removeLoading())
    }
    getProfile(authState)
  }, [profileData?.id, authState.id])

  
  useEffect(()=>{
    if (profileData?.id) {
      
      checkNotifications({
        url: `/sitemeup/notifiche?id_utente=${profileData.id}`,
        manageData: manageNotificationResponse
      })
      console.log(profileData)
    }
  },[profileData?.id])

  const isEmailVerified = profileData?.email_verified_at // state => state.user?.data?.email_verified_at

  const setPageTabs = (event, value) => {
    setTabPage(value)
    if (value === 0) setSelectedPage('profile')
    else if (value === 1 && boolPortfolio) setSelectedPage('portfolio')
    else if (value === 2 && boolWallet) setSelectedPage('wallet')
    else setSelectedPage('profile')

    setSelectedData({})
  }

  const getMetaValue = key => {
    const meta = profileData?.metas?.find(m => m.key === key)
    return meta?.value || null
  }

  const isClient = Boolean(authState.tipo === 'cliente')

  const manageStripeUpdate = (data) => {
    console.log(data)
  }

  useEffect(() => {
    if (profileData?.id && profileData?.tipo === 'freelance') {
      setBoolPortfolio(
        Boolean(
          !isClient &&
            profileData?.telefono &&
            getMetaValue('citta') &&
            getMetaValue('tipo_professionista') &&
            getMetaValue('ragionesociale') &&
            getMetaValue('piva') &&
            getMetaValue('univoco')
        )
      )
      setBoolWallet(Boolean(!isClient && profileData?.images.length > 3))
    }

    const query = new URLSearchParams(window.location.search);
    if (profileData?.id && profileData?.tipo === 'freelance' && query.get('code')) {
      sendStripeConnection({
          url: '/sitemeup/setstrypeid',
          methodH: 'post',
          bodyH: { code:query.get('code'), id_freelance:profileData.id },
          manageData: manageStripeUpdate
        })
        setSelectedPage('wallet')
    }

    if (query.get('error') || query.get('error_description')){
      setSelectedPage('wallet')
    } 
    if (query.get('id_order')){
      setSelectedPage('project-list')
    }

  }, [profileData])



  const manageNotificationsUpdate = () =>{
    checkNotifications({
      url: `/sitemeup/notifiche?id_utente=${profileData.id}`,
      manageData: manageNotificationResponse
    })
  }

  useEffect(()=>{
    if (selectedPage === 'show-alert' && profileData?.id) {
      checkNotifications({
        url: '/sitemeup/notificaupdateforuser',
        methodH: 'post',
        bodyH: { stato:2, id_utente:profileData.id },
        manageData: manageNotificationsUpdate
      })
    }

  }, [selectedPage])


  const ManagePage = ({ page, data }) => {
    let html
    switch (page) {
      case 'profilo':
        html = <ProfileBody profileData={profileData} />
        break
      case 'project-list':
        html = <StoricoProgettiCliente profileData={profileData} onClickEvent={handleSelectProject} />
        break
      case 'project-details':
        html = (<DettagliOfferta offerta={data} setSelectedPage={setSelectedPage} />)
        break
        case 'pagamenti':
          html = <ClientPayments profileData={profileData} />
          break;

      case 'show-alert':
        html = <Notifiche notifications={notifications} />
        break
      default:
        html = <ProfileBody profileData={profileData} />
        break
    }
    return html
  }


  return (
    <>
      {profileData?.id && (
        <>
          <SMUNavbar />
          <SMUModal
            show={!isEmailVerified}
            message="Prego conferma la tua mail per continuare"
            />

          {clientPanel ? (<>
            <div className="container-fluid bg-white p-0 m-0 overflow-hidden">
              <div className="p-0 m-auto ">
                <div className="row gx-0 position-relative">
                  <div className="d-md-none">
                    <SMUDrawer
                      currentpage={selectedPage}
                      setSelectedPage={setSelectedPage}
                    />
                  </div>
                  <div className="col-lg-3 d-none d-md-grid bg-gray">
                    <ClientSidebar
                    profileData={profileData}
                      currentpage={selectedPage}
                      setSelectedPage={setSelectedPage}
                    />
                  </div>
                  <div className="col-12 col-lg-9">
                            {selectedPage === 'profile' && profileData ? (<>
                              <ProfileBody profileData={profileData} />
                            </>) : (
                              <ManagePage page={selectedPage} data={selectedData} />
                            )}

                    
                  </div>
                </div>
              </div>
            </div>



          </>) : (
            <div className="container-fluid bg-gray p-0 m-0 overflow-hidden">
              <div className="p-0 m-auto ">
                <div className="row gx-0 position-relative">
                  {profileData?.approved ? (
                    <>
                      <div className="d-md-none">
                        <Drawer
                          profileData={profileData}
                          setSelectedPage={setSelectedPage}
                          notifications={notifications}
                        />
                      </div>
                      <div className="col-lg-3 d-none d-md-grid">
                        <Sidebar
                          profileData={profileData}
                          setSelectedPage={setSelectedPage}
                          notifications={notifications}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="row justify-content-between align-items-center bg-white py-2 px-0 m-0">
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                          value={tabPage}
                          onChange={setPageTabs}
                          aria-label="basic tabs example"
                        >
                          <Tab label="Profilo" id="simple-tab-0" active />
                          <Tab label="Portfolio" disabled={!boolPortfolio} />
                          <Tab
                            label="Wallet"
                            disabled={!boolWallet || !boolPortfolio}
                          />
                        </Tabs>
                      </Box>
                    </div>
                  )}

                  <div
                    className={`col-lg-9 bg-white ${
                      !profileData.approved && 'col-lg-12'
                    }`}
                  >
                    {selectedPage === 'profile' && profileData ? (
                      <ProfileBody profileData={profileData} />
                    ) : (
                      <></>
                    )}

                    {selectedPage === 'wallet' ? <Wallet profileData={profileData} /> : <></>}

                    {selectedPage === 'portfolio' ? (
                      <Portfolio profileData={profileData} />
                    ) : (
                      <></>
                    )}

                    {selectedPage === 'project-list' ? (<StoricoProgetti profileData={profileData} onClickEvent={handleSelectProject} />) : null}
                    {selectedPage === 'project-details' ? (<DettagliOffertaFreelance profileData={profileData} offerta={selectedData} setSelectedPage={setSelectedPage} />) : null}

                    {selectedPage === 'show-alert' && <Notifiche notifications={notifications} />}
                  </div>
                </div>
              </div>
            </div>
          )}
          <SMUFooter content={footerRoutes} />
        </>
      )}
    </>
  )
}

export default Profilo
