import React from 'react'
import GridTemplate from 'pagesitemeup/Templates/components/GridTemplate'

import './ListTemplates.css'

const ListTemplates = props => {
  const {
    handleBack
    // onEvent
  } = props

  return (
    <div className="h-100 col-12 d-flex flex-wrap flex-column text-secondary p-4 p-sm-5 p-md-5 ">
      <div className="col-12 d-flex flex-wrap">
        <p className="fs-3 text-uppercase">
          Seleziona il <b>template</b>
        </p>
        <p className="col-lg-9">
          Scegli la grafica dal portale più famoso del mondo! Ti proponiamo i
          migliori e gli ultimi template usciti sul web, Provati e verificati da
          noi…
        </p>
      </div>

      <GridTemplate />
    </div>
  )
}

export default ListTemplates
