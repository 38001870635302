import React, { useState, useEffect, useContext } from 'react'

import { AuthContext } from 'store/auth-context'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import LoginForm from 'components/SMU/Login/LoginForm'

import LoginButton from 'components/SMU/Login/LoginButton'

import './LandingNavbar.css'
import './SMUNavbar_main.css'
import logo from 'assets/images/sitemeup/sitemeup_logo-01.svg'
import { clearProfile } from 'slices/profileSlice'
import SMUModal from 'commoncomponets/Modal/SMUModal'

const SMUNavbar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [path, setPath] = useState(null)
  const authContext = useContext(AuthContext)
  const [showModal, setShowModal] = useState(false);
  const {
    authState: { tipo }
  } = authContext
  const [isLoggedIn, setIsLoggedIn] = useState(authContext.isLoggedIn)
  useEffect(() => {
    setIsLoggedIn(authContext.isLoggedIn); 
  }, [authContext])

  console.log(authContext?.authState)

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <LoginForm
        show={path !== null}
        origin={path}
        sendLogin={target => {
          setIsLoggedIn(true)
          navigate(target)
        }}
        sendLogout={() => {
          dispatch(clearProfile)
          setIsLoggedIn(false)
        }}
        close={() => setPath(null)}
      />

        <SMUModal
        show={showModal}
        clear={()=>setShowModal(false)}
        message={(<>
          <h2>Conferma la tua mail ed il tuo telefono</h2>
          <p>Prima di poter inserire un nuovo progetto devi confermare la tua email ed inserire un numero di telefono valido nel tuo profilo.</p>
          <p>Se hai già effettuato queste operazioni, prova a rieffettuare il login per continuare.</p>
        </>)}
      />
      <nav className="navbar navbar-expand-lg navbar-dark bg-secondary z-index-3 py-2 px-0">
        <div className="container-xxl d-flex w-100 justify-content-between px-md-4">
          <a href="/">
            <img src={logo} className="logo" alt="Site Me Up" />
          </a>
          <button
            className={`navbar-toggler shadow-none ms-2 ${
              menuOpen && 'collapsed'
            }`}
            type="button"
            aria-controls="navigation"
            aria-expanded={menuOpen}
            aria-label="Toggle navigation"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span className="navbar-toggler-icon mt-2">
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </span>
          </button>
          <div
            className={`collapse navbar-collapse w-100 pt-3 pb-2 py-lg-0 collapsed ${
              menuOpen && 'show'
            }`}
            id="navigation"
          >
            <ul className="navbar-nav navbar-nav-hover text-uppercase mx-auto justify-content-center">
              <li className="nav-item my-auto">
                <a
                  href="/come-funziona"
                  className="nav-link cursor-pointer text-center mx-1"
                  role="button"
                >
                  Come Funziona
                </a>
              </li>
              <li className="nav-item my-auto">
                <a
                  href="/progetti"
                  className="nav-link cursor-pointer text-center mx-1"
                  role="button"
                >
                  Progetti
                </a>
              </li>
              <li className="nav-item my-auto">
                <a
                  href="/web-designers"
                  className="nav-link cursor-pointer text-center mx-1"
                  role="button"
                >
                  Freelancer
                </a>
              </li>
              <li className="nav-item my-auto">
                <a
                  href="/templates"
                  className="nav-link cursor-pointer text-center mx-1"
                  role="button"
                >
                  I nostri Template
                </a>
              </li>
              <li className="nav-item my-auto">
                <a
                  href="/"
                  className="nav-link cursor-pointer text-center mx-1"
                  role="button"
                >
                  Blog
                </a>
              </li>
              <span className="d-block d-lg-none text-center">
                {authContext?.authState?.tipo === 'cliente' || !isLoggedIn ? (
                <li className="nav-item my-auto">
                  <a
                    href={
                      // eslint-disable-next-line no-nested-ternary
                      isLoggedIn
                        ? tipo === 'cliente'
                          ? '/crea-sito'
                          : '/profilo'
                        : undefined
                    }
                    onClick={() => !isLoggedIn && setPath('/crea-sito')}
                    className="btn border-0 btn-block bg-white text-secondary fs-7 px-2 my-0"
                    role="button"
                  >
                    Crea il tuo sito a
                  </a>
                </li>
                ) : null}
                <li className="nav-item">
                  <LoginButton className="mt-2 nav-link cursor-pointer text-center text-capitalize letter-spacing-1 text-decoration-underline mx-auto" />
                </li>
              </span>
            </ul>

            <ul className="navbar-nav d-lg-flex d-none align-items-center gap-3">
            {authContext?.authState?.tipo === 'cliente' || !isLoggedIn ? (
              <li className="nav-item my-auto">
                <a
                  href={
                    // eslint-disable-next-line no-nested-ternary
                    isLoggedIn && authContext?.authState?.clientedata?.email_verified_at && authContext?.authState?.clientedata?.telefono
                      ? tipo === 'cliente'
                        ? '/crea-sito'
                        : '/profilo'
                      : undefined
                  }
                  onClick={() => {
                      if (isLoggedIn && authContext?.authState?.clientedata.email_verified_at && authContext?.authState?.clientedata.telefono) { 
                        setPath('/crea-sito')
                      } else {
                        setShowModal(true)
                      }
                  }}
                  className="btn border-0 btn-block bg-white text-secondary fs-7 px-2 my-0"
                  role="button"
                >
                  Crea il tuo sito
                </a>
              </li>) : null }
              <li className="nav-item">
                <LoginButton className="nav-link ps-2 cursor-pointer text-center text-capitalize letter-spacing-1 text-decoration-underline" />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default SMUNavbar
