import React, { useState } from 'react'
import {
  // Button,
  TextField,
  // Select,
  // MenuItem,
  // InputLabel,
  FormControl,
  // FormLabel,
  FormGroup,
  FormControlLabel,
  // FormHelperText,
  Checkbox
} from '@mui/material'

import Dialog from '@mui/material/Dialog'
// import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
// import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import './Registration.css'
import logo from 'assets/images/sitemeup/sitemeup_logo-01.svg'
import useHttp from '../../../hooks/use-http'

const Registration = props => {
  const { show, close } = props
  const [registered, setRegistered] = useState(false)
  const [error, setError] = useState(false)
  const {
    sendRequest: sendRegistrationRequest,
    isLoading: isRegistrationLoading
  } = useHttp()

  const [user, setUser] = useState({
    nome: '',
    cognome: '',
    name: '',
    email: '',
    password: '',
    event_detail: {},
    id_category: 1
  })

  const register = () => {
    const usr = { ...user }
    usr.name = usr.email
    sendRegistrationRequest({
      url: '/api/register',
      methodH: 'POST',
      bodyH: usr,
      manageData: e => {
        if (e.status === 'KO') {
          // console.log(e)
          setError(e.message)
        } else {
          // console.log(e)
          setRegistered(true)
          setError(false)
        }
      }
    }).then(() => {})
  }

  const setUserAttr = e => {
    const usr = { ...user }
    if (e.target.type !== 'checkbox') {
      usr[e.target.name] = e.target.value
    } else {
      usr[e.target.name] = JSON.stringify({
        consensoprivacy: 'utilizzo dati',
        testoconsensoprivacy: 'aaaaa',
        consensoutilizzo: 'utilizzo dati',
        testoconsensoutilizzo: 'aaaaaaa'
      })
    }
    setUser(usr)
  }

  // const toggleModal = () => {
  //   close()
  // }

  return (
    <>
      <Dialog open={show} onClose={close} className="SMURegistration">
        <DialogTitle>
          <div className="container">
            <div className="row">
              <div className="col-10 offset-1">
                <img src={logo} alt="SiteMeUp" className="img-fluid" />
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {!registered ? (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <h2 className="text-white mt-3 mb-3">Registrati</h2>
                  </div>
                  {error ? (
                    <>
                      <div className="col-12 text-center">
                        <p className="text-warning">
                          Errore nella richiesta di registrazione:
                          <br />
                          <strong>{error}</strong>
                        </p>
                      </div>
                    </>
                  ) : null}
                  <div className="col-12">
                    <FormControl fullWidth>
                      <TextField
                        required
                        id="outlined-required"
                        label="Nome"
                        defaultValue={user.nome}
                        name="nome"
                        value={user.nome}
                        onChange={setUserAttr}
                        variant="outlined"
                        style={{ background: '#fff', borderRadius: '8px' }}
                      />
                    </FormControl>
                  </div>
                  <div className="col-12 mt-2">
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-required"
                        label="Cognome"
                        defaultValue={user.cognome}
                        name="cognome"
                        value={user.cognome}
                        onChange={setUserAttr}
                        variant="outlined"
                        style={{ background: '#fff', borderRadius: '8px' }}
                      />
                    </FormControl>
                  </div>
                  <div className="col-12 mt-2">
                    <FormControl fullWidth>
                      <TextField
                        required
                        id="outlined-required"
                        label="Email"
                        defaultValue={user.email}
                        name="email"
                        type="email"
                        value={user.email}
                        onChange={setUserAttr}
                        variant="outlined"
                        style={{ background: '#fff', borderRadius: '8px' }}
                      />
                    </FormControl>
                  </div>
                  <div className="col-12 mt-2">
                    <FormControl fullWidth>
                      <TextField
                        required
                        id="outlined-required"
                        label="Password"
                        defaultValue={user.password}
                        name="password"
                        type="password"
                        value={user.password}
                        onChange={setUserAttr}
                        variant="outlined"
                        style={{ background: '#fff', borderRadius: '8px' }}
                      />
                    </FormControl>
                  </div>
                  <div className="col-12 mt-3">
                    <FormControl component="fieldset" variant="standard">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={user.privacy}
                              onChange={setUserAttr}
                              name="event_detail"
                            />
                          }
                          label="Accetto il contratto cliente di site me up e la privacy policy"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 text-center">
                    <button
                      type="button"
                      disabled={isRegistrationLoading}
                      className="btn-lg d-cell border-0 btn-block"
                      onClick={register}
                    >
                      {isRegistrationLoading
                        ? 'Registrazione in corso...'
                        : 'Iscriviti'}
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <p className="mt-3 text-white">
                      Grazie per la tua registrazione.
                    </p>
                    <p className="text-white">
                      Riceverai una mail all&lsquo;indirizzo{' '}
                      <strong>{user.email}</strong> per confermare la tua
                      registrazione.
                    </p>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 text-center">
                    <button
                      type="button"
                      className="btn-lg d-cell border-0 btn-block"
                      onClick={close}
                    >
                      Chiudi
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Registration
