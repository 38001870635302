import LinearProgress from '@mui/material/LinearProgress'

export default function DefaultList({ list = [], completed, onClickEvent }) {

  const statiOrdine = ["Inserito", "Accettato", "In lavorazione", "Concluso", "Controversia", "Annullato"];
console.log(list)
  return (
    <table>
      <tr>
        <th>Nome del progetto</th>
        <th className="text-center">
          {completed ? 'Data consegna' : 'Data pubblicazione'}
        </th>
        <th className="text-center">{completed ? 'Costo' : 'Budget'}</th>
        <th>Numero offerte</th>
        {!completed ? <th>Stato lavori</th> : <></>}
      </tr>
      {list.map(obj => (
        <tr>
          <td className="name-project">
            <button
              type="button"
              onClick={() => onClickEvent('project-details', obj)}
            >
              {obj.name}
            </button>
          </td>
          <td className="date-project">
            {!completed ? obj.dateStart : obj.dateEnd}
          </td>
          <td className="cost-project">€&nbsp;{obj.budget}</td>
          <td className="freelancer">
             
             <span className="text-primary">{obj?.numeroofferte || 0}</span>
          </td>
          <td className="status-project">
            <div>
              {!completed && statiOrdine[obj.status]}
            </div>
          </td>
        </tr>
      ))}
    </table>
  )
}
