import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import Checkbox from '@mui/material/Checkbox'
import Timbro from './images/timbro.jpg';
import ProjectManager from './images/project_manager_2x.png';
import './TeamSelection.css'
import Programmatore from './images/webdeveloper.png';
import ProjectManagerArt from './images/project-manager-art.png';


const TeamSelection = props => {
  const { project, freelances, handleNext, handleBack, sendUpdate } = props
  const { profiles, projectType } = project
  
  const slickSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.25,
    slidesToScroll: 3,
    centerMode:false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.25,
          slidesToScroll: 2,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1
        }
      }
    ]
  };


  const manageProfile = e => {
    if (e.target.checked) {
      if (!profiles.find(el => el?.id_tipofreelance === e.target.value)) {
        sendUpdate('profiles', [
          ...profiles,
          {
            id_tipofreelance: parseInt(e.target.value, 10),
            attivita: '',
            budget: 0,
            answers: []
          }
        ])
      }
    } else {
      sendUpdate(
        'profiles',
        profiles.filter(
          el => el?.id_tipofreelance !== parseInt(e.target.value, 10)
        )
      )
    }
  }

  return (
    <div className="container mx-auto">
      

      <div className="row">
        <div className="col-12">
          <p className="fs-5 text-center">
            <h2>Seleziona il tuo gruppo di lavoro</h2>
          </p>
          
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-9 mx-auto text-center">
        <p>Il <strong>minimo</strong> dei professionisti da dedicare al progetto è già stato attivato.<br />
I limiti sta a te stabilirli.</p>
        </div>
      </div>
      <div className="col-md-9 mx-auto mt-5">
      <Slider {...slickSettings}>
              <div className="item mt-5 px-2">
                <input type="checkbox"
                    className="team-selection"
                    id="projectmanagersmu"
                    name="projectmanagersmu"
                    value="0"
                    disabled
                    checked
                  />
                  <label htmlFor="projectmanagersmu">
                  <input type="checkbox"
                    className="team-selection d-none"
                    id="projectmanagersmu"
                    name="projectmanagersmu"
                    value="0"
                    disabled
                    checked
                  />
                      <img
                        alt="Project Manager"
                        src={ProjectManagerArt}
                        height="190"
                      />
                    
                    <h4>Project Manager</h4>
                  </label>
                </div>

        {freelances &&
          freelances.filter((el)=>
            projectType.tipifreelance.map((ej)=>ej.id_tipofreelance).includes(el.id)
          ).map(el => (
            <>
              <div className="item mt-5 px-2">
                  <input type="checkbox"
                    className="team-selection"
                    id={el.name.replaceAll(" ", "")}
                    name={el.name.replaceAll(" ", "")}
                    value={el.id}
                    disabled={
                      profiles.find(ej => ej?.id_tipofreelance === el.id)
                        ?.obbligatorio === 1
                    }
                    checked={
                      profiles.find(
                        ej =>
                          parseInt(ej?.id_tipofreelance, 10) ===
                          parseInt(el.id, 10)
                      ) || false
                    }
                    onChange={manageProfile}
                  />
                  <label htmlFor={el.name.replaceAll(" ", "")}>
                    {el.foto ? (
                      <>
                        <img alt={el.name} height="190" src={el.foto} />
                      </>
                    ) : (
                      <img
                        alt={el.name}
                        src={el.pathfoto || Programmatore}
                        height="190"
                      />
                    )}
                    <h4>{el.name}</h4>
                  </label>
                </div>
            </>
          ))}

</Slider>

        <div className="container">
          <div className="row">
            <div className="col-12 mt-4">
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-lg btn-primary ms-0 me-auto letter-spacing-1"
                  alt=""
                  onClick={() => handleBack()}
                >
                  <small>Indietro</small>
                </button>
                {profiles.length > 0 ? (
                  <button
                    type="button"
                    className="btn btn-lg btn-primary ms-auto me-0 letter-spacing-1"
                    alt=""
                    onClick={() => handleNext()}
                  >
                    <small>Avanti</small>
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamSelection
